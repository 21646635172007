import Template from './splashscreen.vue'

const getDefaultState = () => {
    return {
        show: false,
        duration: 2
    }
}

let globalOptions = getDefaultState()
let instance

const Init = function(config = {}) {
    let Tpl = this.extend(Template)

    if (!instance) instance = new Tpl()
    config = {
        ...globalOptions,
        ...instance.$data,
        ...config
    }
    for (let key in config) {
        if (config.hasOwnProperty(key)) {
            instance.$data[key] = config[key]
        }
    }
}

const Show = function(config = {}) {
    Init.call(this, config)

    instance.$data.show = true
    document.body.style.overflow = 'hidden'
    document.body.appendChild(instance.$mount().$el)
}

const Hide = function() {
    if (typeof instance != 'undefined') {
        Object.assign(instance.$data, getDefaultState())
    }
}

export default {
    install(Vue) {
        Vue.prototype.$splashscreen = Init.bind(Vue)
        Vue.prototype.$splashscreen.show = Show.bind(Vue)
        Vue.prototype.$splashscreen.open = Show.bind(Vue)
        Vue.prototype.$splashscreen.hide = Hide.bind(Vue)
        Vue.prototype.$splashscreen.close = Hide.bind(Vue)
    }
}
