import { Api } from '@/core/api.js'
const api = new Api()

const state = {
    tickets: {},
    chatLoaded: false
}

const getters = {
    getList: (state, getters, rootState) => {
        return state.tickets
    },
    getById: (state, getters, rootState) => id => {
        return state.tickets[id]
    },
    getThreads: (state, getters, rootState) => id => {
        const ticket = state.tickets[id]
        return typeof ticket.threads !== 'undefined' ? ticket.threads : {}
    },
    getChatLoaded: (state, getters, rootState) => {
        return state.chatLoaded
    }
}

const actions = {
    loadList(context, params) {
        return api.login('ticket/list', { location_id: params.location_id }).then(function(response) {
            return response.status ? context.commit('setList', response.data) : false
        })
    },
    getTreads(context, params) {
        return api.login(`ticket/${params.id}/threads`, {}).then(response => {
            return response.status ? context.commit('setTicketThreads', { ticketId: params.id, data: response.data }) : false
        })
    },
    addTicket(context, params) {
        return api.login('ticket/add', { location_id: params.location_id, employee_id: params.employee_id, tool: params.tool, description: params.description, email: params.email, data: typeof params.data !== 'undefined' ? JSON.stringify(params.data) : '', language: params.language }).then(function(response) {
            return true
        })
    },
    addMessage(context, params) {
        return api.login(`ticket/${params.id}/thread/addmessage`, { message: params.message, employee_id: params.employee_id }).then(response => {
            return response.status ? context.commit('setTicketThread', { ticketId: params.id, data: response.data }) : false
        })
    }
}

const mutations = {
    setList(state, data) {
        state.tickets = data
    },

    setChatLoaded(state, data) {
        state.chatLoaded = data
    },

    setTicket(state, data) {
        let tickets = state.tickets
        tickets[data.id] = data
        state.tickets = { ...tickets }
        Vue.set(state, 'tickets', { ...state.tickets })
    },

    setTicketThreads(state, data) {
        let tickets = state.tickets
        tickets[data.ticketId].threads = data.data
        state.tickets = { ...tickets }
        Vue.set(state, 'tickets', { ...state.tickets })
    },

    setTicketThread(state, data) {
        let tickets = state.tickets
        if (typeof tickets[data.ticketId].threads == 'undefined') {
            tickets[data.ticketId].threads = {}
        }
        tickets[data.ticketId].threads[data.data.id] = data.data
        state.tickets = { ...tickets }
        Vue.set(state, 'tickets', { ...state.tickets })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
