import { Api } from '@/core/api.js'
import localforage from 'localforage'

const api = new Api()
const db_timezones = localforage.createInstance({ name: 'alexdb', storeName: 'timezones' })
const db_languages = localforage.createInstance({ name: 'alexdb', storeName: 'languages' })

const getDefaultState = () => {
    return {
        timezones: {},
        languages: {}
    }
}

const state = getDefaultState()

const getters = {
    getTimeZones: (state, getters, rootState) => {
        return state.timezones
    },
    getLanguages: (state, getters, rootState) => {
        return state.languages
    }
}

const actions = {
    async loadInformation(context, params) {
        const timezonesItems = await db_timezones.length()
        const languagesItems = await db_languages.length()

        if (timezonesItems == 0 || languagesItems == 0) {
            const response = await api.get('account/information')

            if (response.status) {
                for (let id in response.data.timezones) {
                    const timezone = response.data.timezones[id]
                    db_timezones.setItem(id, timezone)
                }

                for (let id in response.data.languages) {
                    const language = response.data.languages[id]
                    db_languages.setItem(id, language)
                }

                context.commit('setInformation', response.data)
            }

            return true
        }

        const timezones = {}
        const languages = {}

        await db_timezones.iterate((value, key, iterationNumber) => {
            timezones[key] = value
        })

        await db_languages.iterate((value, key, iterationNumber) => {
            languages[key] = value
        })

        context.commit('setInformation', { timezones: timezones, languages: languages })
    },
    updateInformation(context, params) {
        return api.post('location/edit', params).then(function(response) {
            return true
        })
    }
}

const mutations = {
    setInformation(state, data) {
        state.timezones = data.timezones
        state.languages = data.languages
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
