<template>
    <div id="loader" ref="loader" :class="$mq">
        <div id="content">
            <div class="scheme">
                <div class="andy"></div>
                <div class="loader"></div>
                <div class="printer"></div>
            </div>
            <div class="progress">
                <div class="bar">
                    <span class="completed" :style="'width: ' + progressBar + '%'"></span>
                </div>
                <template v-if="procesingTemplates.total != -1">
                    <div class="status">{{ getString('config.printer.templates_transfer') }} {{ procesingTemplates.current }} / {{ procesingTemplates.total }}</div>
                </template>
                <template v-else>
                    <div class="status">{{ getString('config.printer.init') }}</div>
                </template>
            </div>
        </div>
        <div class="cancel" @click="cancel()">Cancelar</div>
    </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'

export default {
    data() {
        return {
            progressBar: 0,
            currentTotal: null
        }
    },
    computed: {
        procesingTemplates() {
            return store.getters['printer/getProcessingTemplates']
        }
    },
    methods: {
        getString(str) {
            return i18n.t(str)
        },

        cancel() {
            document.body.style.overflow = 'auto'
            this.progressBar = 0
            this.$el.parentNode.removeChild(this.$el)
        }
    },
    created() {},
    updated() {
        var self = this

        var currentTemplate = 1
        var totalTemplates = JSON.parse(JSON.stringify(self.procesingTemplates)).total
        function increaseBar() {
            self.currentTotal = totalTemplates
            log('increaseBar: ', currentTemplate, totalTemplates)
            var seconds = 10

            let current = (self.procesingTemplates.current / self.procesingTemplates.total) * 100

            let max = Math.ceil(self.progressBar / (current / seconds) + 1) * (current / seconds)
            // let min = self.progressBar

            if (currentTemplate && currentTemplate != self.procesingTemplates.current) {
                // console.log('A - ' + currentTemplate + '/' + self.procesingTemplates.current)
                self.progressBar = ((self.procesingTemplates.current - 1) / self.procesingTemplates.total) * 100
                currentTemplate = JSON.parse(JSON.stringify(self.procesingTemplates.current))
            } else {
                // console.log('B - ' + currentTemplate + '/' + self.procesingTemplates.current)
                self.progressBar = max
            }

            if (self.progressBar >= 90 || totalTemplates > self.procesingTemplates.total) {
                clearInterval(incrementBar)
                currentTemplate = 1
            }
        }
        if (totalTemplates != -1 && self.currentTotal != totalTemplates) {
            var incrementBar = setInterval(increaseBar, 1000)
        }
    },
    beforeDestroy() {
        this.progressBar = 0
    }
}
</script>

<style lang="scss">
#loader {
    background-color: #f4f2ed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: $z-overlay-default;
    z-index: 80800000000000;

    #content {
        @include display-flex();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items();
        height: 100%;
        padding: 0 30px;
        max-width: 600px;
        margin: 0 auto;

        .scheme {
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            width: 100%;
            height: auto;
            padding-bottom: 50px;

            .andy,
            .loader,
            .printer {
                height: 80px;
                width: 80px;
                margin: 0 10px;
            }
            .andy {
                @include font-size(s);
                @include background($image: img('alex_carita_10.svg'), $size: 80px);
            }
            .loader {
                width: 100px;
                @include background($image: img('loader_main.svg'), $size: 80px);
            }
            .printer {
                @include background($image: img('printer.svg'), $size: 80px);
            }
        }

        .progress {
            width: 100%;
            padding-bottom: 50px;
            .bar {
                @include border-radius(3px);
                background: $neutro-light;
                margin: 0 0 30px;
                width: 100%;
                height: 10px;
                overflow: hidden;
                position: relative;

                .completed {
                    background: $main-dark;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    transition: 0.1s;
                }
            }

            .status {
                text-align: center;
                width: 100%;
                @include font-size(sm);
                font-family: $text;
            }
        }
    }

    .cancel {
        @include interaction();
        @include font-size(s);
        font-family: $text;
        position: absolute;
        bottom: 40px;
        left: calc(50vw - 30px);
        color: $main;
        text-decoration: underline;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#loader.portrait {
    #content {
        .scheme {
            .andy,
            .loader,
            .printer {
                height: 50px;
                width: 50px;
                background-size: 50px;
            }
        }
        .progress {
            .status {
                @include font-size(xs);
            }
        }
    }

    .cancel {
        @include font-size(xs);
    }
}
</style>
