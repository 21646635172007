import Template from './loader.vue'
import i18n from '@/i18n'

const getDefaultState = () => {
    return {
        show: false
    }
}

let globalOptions = getDefaultState()

let instance

const Init = function(config = {}) {
    let Tpl = this.extend(Template)

    if (!instance) instance = new Tpl()
    config = {
        ...globalOptions,
        ...instance.$data,
        ...config
    }
    for (let key in config) {
        if (config.hasOwnProperty(key)) {
            instance.$data[key] = config[key]
        }
    }
}

const Show = function(config = {}) {
    Init.call(this, config)

    instance.$data.show = true
    document.body.style.overflow = 'hidden'
    document.body.appendChild(instance.$mount().$el)
}

const Close = function(config = {}) {
    if (instance) {
        instance.$data.show = false
        document.body.removeChild(instance.$mount().$el)
        instance = undefined
    }
}

export default {
    install(Vue) {
        Vue.prototype.$loader = Init.bind(Vue)
        Vue.prototype.$loader.show = Show.bind(Vue)
        Vue.prototype.$loader.open = Show.bind(Vue)
        Vue.prototype.$loader.loading = Show.bind(Vue)
        Vue.prototype.$loader.printing = Show.bind(Vue)
        Vue.prototype.$loader.hide = Close.bind(Vue)
        Vue.prototype.$loader.close = Close.bind(Vue)
    }
}
