<template>
    <div>
        <v-container id="filter-sidebar" fluid class="slideRight">
            <div class="title">Columnas a mostrar:</div>

            <template v-for="(header, header_index) in list">
                <div v-if="!header.always_display" class=" line" :key="header_index">
                    <v-switch color="#8BC0FF" v-model="header.visible" :disabled="header.blocked" @change="select(header)"></v-switch>
                    <label class="keep-format" v-html="header.text"></label>
                </div>
            </template>

            <!-- <v-switch color="#8BC0FF" v-for="header in list" v-model="header.visible" :disabled="header.blocked" @change="select(header)" :label="header.text"></v-switch> -->
        </v-container>
        <div @click="hide(this)" id="filter-overlay"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            list: {},
            callback: function() {},
            selected: []
        }
    },
    methods: {
        select(column) {
            if (!column.visible) {
                this.selected.push(column.value)
            } else {
                var idx = this.selected.indexOf(column.value)
                if (idx != -1) {
                    this.selected.splice(idx, 1)
                }
            }

            this.callback(this.selected)
        },
        hide(event) {
            this.$filter.close()
            this.show = false
            document.body.style.overflow = 'auto'
        }
    }
}
</script>

<style lang="scss">
#filter-overlay {
    background-color: #00000099;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-overlay-default;
}

#filter-sidebar {
    @include font-size(s);
    background-color: #fff;
    color: #111;
    position: fixed;
    left: 0;
    top: 0;
    width: 350px;
    height: calc(100%);
    overflow-y: auto;
    z-index: $z-popups-default;
    padding: 20px 25px;
    padding-bottom: $menu-aux-height;

    .title {
        @include font-size(sm);
        margin-bottom: 15px;
    }

    .v-input__slot {
        margin: 0;
    }

    .line {
        line-height: 30px;
    }

    label {
        @include font-size(s);
        line-height: 14px;
        margin: 0;
        padding: 0;
        display: inline;
    }

    .v-input {
        display: inline-block;
        margin: 0;
        padding: 0;
    }
}
</style>
