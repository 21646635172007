import { Api } from '@/core/api.js'
import localforage from 'localforage'
const api = new Api()

const state = {
    pusher: {},
    channel: {}
}

const getters = {
    getPusher: (state, getters, rootState) => {
        return state.pusher
    },
    getChannel: (state, getters, rootState) => {
        return state.channel
    }
}

const actions = {
    async init(context, params) {
        Pusher.logToConsole = true

        const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })
        var token = await db_login.getItem('token')
        log('INIT - PUSHER', process.env.VUE_APP_API_URL + 'pusher/auth&authorization=' + token)

        // var pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        //     cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        //     authEndpoint: process.env.VUE_APP_API_URL + 'pusher/auth',
        //     authTransport: 'ajax',
        //     auth: {
        //         headers: { authorization: token }
        //     }
        // })
        log('FIN')

        // context.commit('setPusher', pusher)
        // context.dispatch('subscribeChannel')
    },

    subscribeChannel(context, params) {
        var pusher = context.getters.getPusher
        var channel = pusher.subscribe('private-channel')
        channel.bind('my-event', data => {
            // Method to be dispatched on trigger.
            log('NEW PUSHER - ', data)
            context.commit('setPusherData', data, { root: true })
        })
        context.commit('setChannel', channel)
    }
}

const mutations = {
    setPusher(state, data) {
        state.pusher = data
        Vue.set(state, 'pusher', data)
    },
    setChannel(state, data) {
        state.channel = data
        Vue.set(state, 'channel', data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
