import i18n from '@/i18n'

export default {
    data() {
        return {
            pStart: { x: 0, y: 0 },
            pStop: { x: 0, y: 0 },
            pCount: 0
        }
    },
    computed: {},
    methods: {
        handleFocusKeyboard(inst, scroll) {
            if (process.env.VUE_APP_IS_APP === 'TRUE') {
                // console.log(" -- FOCUS");

                // Ocultamos lo que normalmente no nos interesa visualizar

                // Menu tools visibility
                // document.getElementById('menu-tools').style.visibility = 'hidden';
                document.getElementsByClassName('expand-tools')[0].style.visibility = 'hidden'

                // Actions bar
                inst.$bar.hide()

                if (typeof scroll != 'undefined') {
                    this.scrollTo(scroll)
                }
            }
        },
        handleBlurKeyboard(inst) {
            if (process.env.VUE_APP_IS_APP === 'TRUE') {
                // console.log(" -- BLUR");

                // Mostramos lo que hemos ocultado en el focus
                // Menu tools visibility
                // document.getElementById('menu-tools').style.visibility = 'visible';
                document.getElementsByClassName('expand-tools')[0].style.visibility = 'visible'

                // Actions bar
                inst.$bar.show()
            }
        },

        scrollTo(scroll) {
            scroll.offset = typeof scroll.offset == 'undefined' ? 0 : scroll.offset

            // console.log("container");
            // console.log(scroll.container);
            // console.log("target");
            // console.log(scroll.target.offsetTop);

            this.doScroll(scroll.container, scroll.target.offsetTop + scroll.offset, 200)
        },
        easeInOutQuad(t, b, c, d) {
            t /= d / 2
            if (t < 1) return (c / 2) * t * t + b
            t--
            return (-c / 2) * (t * (t - 2) - 1) + b
        },
        doScroll(container, target, duration) {
            // console.log("container");
            // console.log(container);
            // console.log("target");
            // console.log(target);

            var self = this
            var start = container.scrollTop,
                change = target - start,
                currentTime = 0,
                increment = 20

            var animateScroll = function() {
                currentTime += increment
                var val = self.easeInOutQuad(currentTime, start, change, duration)
                container.scrollTop = val
                if (currentTime < duration) {
                    setTimeout(animateScroll, increment)
                }
            }
            animateScroll()
        },

        redirectPath(firstResult) {
            var visible = []
            var tools = this.$store.getters['getTools']
            var result = firstResult
            const config = this.$store.getters['login/getConfig']

            if (config && result.indexOf('config') == -1 && result.indexOf('tickets') == -1) {
                if (config.tools) {
                    visible = config.tools
                }
            }
            if (visible.length == 1) {
                result = tools[visible[0]].action
            }

            return result
        },

        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(email).toLowerCase())
        },

        getFirstLetters(string) {
            if (typeof string != 'undefined') {
                let aux = string.split(' ')
                let first = []

                for (let i in aux) {
                    let item = aux[i]
                    first.push(item.charAt(0))
                }
                let result = first.join('').trim()

                return result
            } else {
                return ''
            }
        },

        timeLeft(deadtime) {
            // var now = store.getters['getMoment'];
            deadtime = moment(deadtime)
            var now = moment()
            var text = ''

            var years = deadtime.diff(now, 'year')
            now.add(years, 'years')

            var months = deadtime.diff(now, 'months')
            now.add(months, 'months')

            var days = deadtime.diff(now, 'days')
            now.add(days, 'days')

            var hours = deadtime.diff(now, 'hours')
            now.add(hours, 'hours')

            var minutes = deadtime.diff(now, 'minutes')
            now.add(minutes, 'minutes')

            var seconds = deadtime.diff(now, 'seconds')
            now.add(seconds, 'seconds')

            if (years > 0) {
                if (years == 1) {
                    text += years + ' ' + i18n.tc('assets.timing.year', 1)
                } else if (years > 1) {
                    text += years + ' ' + i18n.tc('assets.timing.year', 2)
                }
            } else if (years <= 0 && months > 0) {
                if (months == 1) {
                    text += months + ' ' + i18n.tc('assets.timing.month', 1)
                } else if (months > 1) {
                    text += months + ' ' + i18n.tc('assets.timing.month', 2)
                }

                if (text.length > 1 && days != 0) {
                    text += ' ' + i18n.t('assets.timing.connector') + ' '
                }

                if (days == 1) {
                    text += days + ' ' + i18n.tc('assets.timing.day', 1)
                } else if (days > 1) {
                    text += days + ' ' + i18n.tc('assets.timing.day', 2)
                }
            } else if (years <= 0 && months <= 0 && days > 0) {
                if (days == 1) {
                    text += days + ' ' + i18n.tc('assets.timing.day', 1)
                } else if (days > 1) {
                    text += days + ' ' + i18n.tc('assets.timing.day', 2)
                }

                if (text.length > 1 && hours != 0) {
                    text += ' ' + i18n.t('assets.timing.connector') + ' '
                }

                if (hours == 1) {
                    text += hours + ' ' + i18n.tc('assets.timing.hour', 1)
                } else if (hours > 1) {
                    text += hours + ' ' + i18n.tc('assets.timing.hour', 2)
                }
            } else if (years <= 0 && months <= 0 && days <= 0 && hours > 0) {
                if (hours == 1) {
                    text += hours + ' ' + i18n.tc('assets.timing.hour', 1)
                } else if (hours > 1) {
                    text += hours + ' ' + i18n.tc('assets.timing.hour', 2)
                }

                if (text.length > 1 && minutes != 0) {
                    text += ' ' + i18n.t('assets.timing.connector') + ' '
                }

                if (minutes == 1) {
                    text += minutes + ' ' + i18n.tc('assets.timing.minute', 1)
                } else if (minutes > 1) {
                    text += minutes + ' ' + i18n.tc('assets.timing.minute', 2)
                }
            } else if (years <= 0 && months <= 0 && days <= 0 && hours <= 0 && minutes > 0) {
                if (minutes == 1) {
                    text += minutes + ' ' + i18n.tc('assets.timing.minute', 1)
                } else if (minutes > 1) {
                    text += minutes + ' ' + i18n.tc('assets.timing.minute', 2)
                }
            } else if (years <= 0 && months <= 0 && days <= 0 && hours <= 0 && minutes <= 0 && seconds > 0) {
                text += i18n.t('assets.timing.inminent')
            }

            return i18n.t('assets.timing.remind', { s: text })
        }
    }
}
