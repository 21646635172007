<template>
    <div class="dateTimeContainer hide-scrollbar">
        <template v-if="bShowCalendar">
            <div class="datePicker">
                <CalendarSelector
                    :givenValue="newDate ? newDate : date"
                    ref="calendarSelector"
                    @input="updateDate($event)"
                ></CalendarSelector>
            </div>
        </template>
        <template v-else>
            <div class="dateModalContainer">
                <div
                    class="calendarModal"
                    @click="bShowCalendar = true"
                >
                    {{ date }}
                </div>
            </div>
        </template>
        <div class="timePicker">
            <HourTimeSelector
                :show="true"
                :givenValue="time"
                ref="hourTimeSelector"
                @input="updateTime($event)"
            ></HourTimeSelector>
        </div>
    </div>
</template>

<script>
import HourTimeSelector from '@/components/inputs/HourTimeSelector'
import CalendarSelector from '@/components/inputs/CalendarSelector'

export default {
    name: 'PopupDateTime',
    components: { HourTimeSelector, CalendarSelector },
    props: {
        date: {
            type: String
        },
        time: {
            type: String
        }
    },
    data () {
        return {
            newDate: false,
            newTime: false,
            bShowCalendar: false
        }
    },
    computed: {},
    methods: {
        getData () {
            return {
                date: this.newDate ? moment(this.newDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : this.date,
                time: this.newTime ? this.newTime : this.time,
                oldDate: this.date,
                oldTime: this.time
            }
        },
        updateDate ($event) {
            this.newDate = moment($event, 'YYYY/MM/DD').format('DD/MM/YYYY')
        },
        updateTime ($event) {
            this.newTime = $event
        }
    },
    mounted () { },
    created () { }
}
</script>

<style lang="scss">
.dateTimeContainer {
    @include display-flex();
    @include justify-content();
    @include align-items();
    @include flex-wrap(wrap);
    height: auto;
    min-height: 300px;
    padding: 9px 15px;

    .datePicker {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        height: 100%;
        width: auto;
        max-width: 50%;
        padding: 0 15px;
        min-width: 350px;

        .calendar-container {
            margin: 0;

            .vfc-calendars {
                @include background($color: #fff);
                @include border-radius(4px);
                padding: 6px;

                .vfc-calendar {
                    @include display-flex();
                    @include justify-content(flex-start);
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    height: auto;
                }
            }

            .vfc-navigation-buttons {
                .vfc-cursor-pointer {
                    margin-top: 10px !important;
                }
            }
        }
    }

    .dateModalContainer {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        height: 100%;
        width: auto;
        max-width: 50%;
        padding: 0 15px;

        .calendarModal {
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-wrap(wrap);
            @include border-radius(4px);
            @include background($color: $main-t90, $image: img('input_datetime_primary.svg'), $size: 20px, $position: left 10px center);
            @include font-size(m);
            font-family: $conden-bold;
            height: 40px;
            width: 200px;
            text-align: left;
            color: $main-s10;
            cursor: pointer;
            padding-left: 15px;
        }
    }

    .timePicker {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        width: auto;
        max-width: 50%;
        height: 100%;
        padding: 0 15px;
    }
}
</style>
