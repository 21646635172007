import Vue from 'vue'
import i18n from '@/i18n'

import { Api } from '@/core/api.js'
const api = new Api()

import localforage from 'localforage'

var store = localforage.createInstance({ name: 'alexdb', storeName: 'logbook' })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        logs: {},
        logsOrdered: {},
        selected: false
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getAllLogs: (state, getters, rootState) => {
        return state.logs
    },
    getAllLogsOrdered: (state, getters, rootState) => {
        //mutations.orderLogs(state, {logs: state.logs});
        return state.logsOrdered
    },
    getLog: (state, getters, rootState) => id => {
        return state.logs[id]
    },

    getSelected: (state, getters, rootState) => {
        log(state.logs)
        log(state.logsOrdered)
        if (state.selected) return state.logs[state.selected]
        return false
    }
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    resetState({ commit }) {
        commit('resetState')
    },

    loadLogs(context, params) {
        return api.get('logbook' + (params.date ? '/' + params.date : ''), {}).then(function(response) {
            var raw = prepare_messages(response.data)
            _.forEach(raw, function(value, key) {
                store.setItem(key, value)
            })
            var data = format_messages(response.data, context)
            context.commit('setMessages', data)
            context.commit('orderLogs', data)
            //context.commit('formatMessages', { logs: response.data })
        })
    },

    attachImage(context, params) {
        return api.post('logbook/attach/' + params.id, { file: params.image }).then(function(response) {
            return response
        })
    },
    attachFiles(context, params) {
        return api.post('logbook/attachFile/' + params.id, { file: params.files }).then(function(response) {
            return response
        })
    },

    viewed(context, params) {
        return api.get('logbook/view/' + params.id).then(function(response) {
            return response
        })
    },

    addLog(context, params) {
        var call = {}
        if (params.parent_id) {
            call = { parent_id: params.parent_id, employee: params.employee, message: params.msg, image: params.image }
        } else {
            call = { employee: params.employee, message: params.msg, image: params.image, files: params.files }
        }

        return api.post('logbook/add', call).then(function(response) {
            return response
        })
    },
    editLog(context, params) {
        return api.post('logbook/edit/' + params.id, { message: params.msg, image: params.image, files: params.files }).then(function(response) {
            return response
        })
    },
    deleteLog(context, id) {
        return api.post('logbook/remove/' + id, {}).then(function(response) {
            return response
        })
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    deleteMessages(state, id) {
        Vue.delete(id, state.logs)
    },
    setMessages(state, response) {
        Vue.set(state, 'logs', { ...response })
    },
    select(state, noteId) {
        state.selected = noteId
    },

    /*formatMessages (state, payload) {
      var append = typeof payload.append != 'undefined' ? payload.append : false;
      var isNotif = typeof payload.isNotif != 'undefined' ? payload.isNotif : false;
  
      if( append ) {
        var bLogs = isNotif ? state.notif : state.logs;
      } else {
        var bLogs = {};
      }
  
      Object.keys(payload.logs).map(function (log_id, i) {
        var log = payload.logs[log_id];
  
          if ([2,3].indexOf( parseInt(log.type) ) != -1){
              log.type = "notification";
          } else {
              log.type = "";
          }
          log.type = "";
          log.created_date = moment(log.created_date * 1000);
          log.formated_date = log.created_date.fromNow();
  
          if( log.employee.id == localStorage.employee_id ){
              log.actions = { edit: true, delete: true };
          } else {
              log.actions = {};
          }
  
          Object.keys(log.comments.data).map(function (comment_id, i) {
            var comment = log.comments.data[comment_id];
  
            if( comment.employee.id == localStorage.employee_id ){
                comment.actions = { edit: true, delete: true };
            } else {
                comment.actions = {};
            }
            comment.created_date = moment(comment.created_date * 1000);
            comment.formated_date = comment.created_date.fromNow();
          });
  
  
          bLogs[log_id] = log;
      });
  
      if( isNotif ){
        state.notif = bLogs;
        Vue.set(state, 'notif', {...state.notif} );
      } else {
        state.logs = bLogs;
        Vue.set(state, 'logs', {...state.logs} );
      }
  
    },*/

    orderLogs(state, logs) {
        var bLogsO = {}

        Object.keys(logs).map(function(log_id, i) {
            var log = logs[log_id]
            if (log.created_date != 'undefined') {
                var formated = moment(log.created_date)
                    .startOf('day')
                    .format('YYYY-MM-DD')

                if (log.tool) {
                    log.message = i18n.t('logbook.reference.' + log.tool + '.' + log.event, [log.ref1.label, log.ref2.label, log.ref3.label, log.ref1.tool, log.ref1.id, log.ref2.tool, log.ref2.id, log.ref3.tool, log.ref3.id])
                }

                if (typeof bLogsO[formated] != 'undefined') {
                    bLogsO[formated].data[log.id] = log
                } else {
                    bLogsO[formated] = {
                        title: moment(log.created_date).calendar(null, {}),
                        date: formated,
                        data: {}
                    }
                    bLogsO[formated].data[log.id] = log
                }
            }
        })

        state.logsOrdered = bLogsO
        Vue.set(state, 'logsOrdered', { ...state.logsOrdered })
    }
}

function prepare_messages(data) {
    var messages = {}
    Object.keys(data).map(function(key, i) {
        var log = _.cloneDeep(data[key])
        log.created_date = moment(log.created_date * 1000).unix()

        Object.keys(log.comments.data).map(function(comment_id, i) {
            var comment = log.comments.data[comment_id]
            comment.created_date = moment(log.created_date * 1000).unix()
        })
        messages[log.id] = log
    })
    return messages
}

function format_messages(data, context) {
    const employee_id = context.rootGetters['loginUser/getLocalEmployee']

    var messages = {}
    Object.keys(data).map(function(key, i) {
        var log = data[key]

        log.created_date = moment(log.created_date * 1000)
        log.is_sticked = false
        // TODO saber si esta "fijado" o no

        log.actions = {}
        if (log.employee.id == employee_id) {
            log.actions = { stick: false, edit: true, delete: true }
        }

        Object.keys(log.comments.data).map(function(comment_id, i) {
            var comment = log.comments.data[comment_id]

            comment.created_date = moment(comment.created_date * 1000)
            comment.is_sticked = false
            // TODO saber si esta "fijado" o no

            comment.actions = {}
            if (comment.employee.id == employee_id) {
                comment.actions = { stick: false, edit: true, delete: true }
            }
        })

        messages[log.id] = log
    })
    return messages

    /*Object.keys(payload.logs).map(function (log_id, i) {
      var log = payload.logs[log_id];
  
        if ([2,3].indexOf( parseInt(log.type) ) != -1){
            log.type = "notification";
        } else {
            log.type = "";
        }
        log.type = "";
        log.created_date = moment(log.created_date * 1000);
        log.formated_date = log.created_date.fromNow();
  
        if( log.employee.id == localStorage.employee_id ){
            log.actions = { edit: true, delete: true };
        } else {
            log.actions = {};
        }
  
        Object.keys(log.comments.data).map(function (comment_id, i) {
          var comment = log.comments.data[comment_id];
  
          if( comment.employee.id == localStorage.employee_id ){
              comment.actions = { edit: true, delete: true };
          } else {
              comment.actions = {};
          }
          comment.created_date = moment(comment.created_date * 1000);
          comment.formated_date = comment.created_date.fromNow();
        });
  
  
        bLogs[log_id] = log;
    });
  
    if( isNotif ){
      state.notif = bLogs;
      Vue.set(state, 'notif', {...state.notif} );
    } else {
      state.logs = bLogs;
      Vue.set(state, 'logs', {...state.logs} );
    }*/
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
