import Vue from "vue";
import VueI18n from "vue-i18n";
import { Api } from "@/core/api.js";

// import es from "./es.json";
// import en from "./en.json";
// import pt from "./pt.json";
import init from "./init.json";
const api = new Api();

const defaultLocale = localStorage.language ? localStorage.language : "init";

moment.updateLocale("es", {
    calendar: {
        lastDay: "[Ayer]",
        sameDay: "[Hoy]",
        nextDay: "[Mañana]",
        lastWeek: "[El pasado] dddd",
        nextWeek: "[La próxima semana]",
        sameElse: "dddd, DD [de] MMMM [del] YYYY",
    },
});
moment.updateLocale("en", {
    calendar: {
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        lastWeek: "[Last] dddd",
        nextWeek: "[The next week]",
        sameElse: "dddd, DD [of] MMMM [of] YYYY",
    },
});
moment.updateLocale("pt", {
    calendar: {
        lastDay: "[Ontem]",
        sameDay: "[Hoje]",
        nextDay: "[Manhã]",
        lastWeek: "dddd [passada]",
        nextWeek: "[A próxima semana]",
        sameElse: "dddd, DD [de] MMMM [de] YYYY",
    },
});
moment.updateLocale("de", {
    calendar: {
        lastDay: "[Gestern]",
        sameDay: "[Heute]",
        nextDay: "[Morgen]",
        lastWeek: "[Letzter] dddd",
        nextWeek: "[Nächste Woche]",
        sameElse: "dddd, DD. MMMM YYYY",
    },
});
moment.locale(defaultLocale);

Vue.use(VueI18n);

const languages = {
    init: init,
};
// const languages = {
//     es: es,
//     en: en,
//     pt: pt
//   };

const i18n = new VueI18n({
    //   locale: defaultLocale,
    locale: "init",
    fallbackLocale: "init",
    messages: Object.assign(languages),
    silentTranslationWarn: true,
});
const loadedLanguages = ["init"];

i18n.loadLanguageAsync = function (lang) {
    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            return api.language(lang).then((response) => {
                log(typeof response);
                if (typeof response === "string")
                    response = JSON.parse(response);
                i18n.setLocaleMessage(lang, response);
                moment.locale(lang);
                loadedLanguages.push(lang);
                return setI18nLanguage(lang);
            });
        }
        return Promise.resolve(setI18nLanguage(lang));
    } else if (fnCheckConnection() && lang == "init") {
        // Download es.json lang for default if it is online
        lang = "es";
        moment.locale(lang);
        return api.language(lang).then((response) => {
            i18n.setLocaleMessage(lang, response);
            loadedLanguages.push(lang);
            return setI18nLanguage(lang);
        });
    }
    return Promise.resolve(lang);
};

i18n.loadLanguageAsync(defaultLocale);

export default i18n;

function setI18nLanguage(lang) {
    log("setI18nLanguage", lang);
    i18n.locale = lang;
    moment.locale(lang);
    document.querySelector("html").setAttribute("lang", lang);
    log(i18n.messages);
    return lang;
}
