<template>
    <div class="popupTicket hide-scrollbar">
        <!-- Tooltip -->
        <div class="item helpcenter_tooltip">
            <div class="info">
                <div class="leftPart">
                    <div class="text">{{ translate('tickets.tooltip.part1') }}</div>
                    <div class="text">{{ translate('tickets.tooltip.part2') }}</div>
                </div>
                <div class="rightPart">
                    <div class="button" @click="openHelpCenter()">{{ translate('tickets.tooltip.helpcenter') }}</div>
                </div>
            </div>
        </div>

        <!-- Location -->
        <div class="item location">
            <div class="label">{{ translate('tickets.location') }}</div>
            <div class="label-error" v-if="error.location">
                <div class="text">{{ translate('tickets.required_field') }}</div>
            </div>
            <div class="input">
                <input v-model.trim="location" :placeholder="translate('tickets.location_placeholder')" :class="{ error: error.location }" :disabled="blockLocation" />
            </div>
        </div>

        <!-- Name -->
        <div class="item name">
            <div class="label">{{ translate('tickets.name') }}</div>
            <div class="label-error" v-if="error.name">
                <div class="text">{{ translate('tickets.required_field') }}</div>
            </div>
            <div class="input">
                <input v-model.trim="name" :placeholder="translate('tickets.name_placeholder')" :class="{ error: error.name }" />
            </div>
        </div>

        <!-- Tool -->
        <div class="item tool">
            <div class="label">{{ translate('tickets.tool') }}</div>
            <div class="label-error" v-if="error.tool">
                <div class="text">{{ translate('tickets.required_field') }}</div>
            </div>
            <div class="selector">
                <select v-model="selectedTool" :class="{ error: error.tool }">
                    <option v-for="option in options" :key="option.key" :value="option.key">{{ option.value }}</option>
                </select>
            </div>
        </div>

        <!-- Description -->
        <div class="item description">
            <div class="label">{{ translate('tickets.description.title1') }}</div>
            <div class="sublabel">{{ translate('tickets.description.title2') }}</div>
            <div class="label-error" v-if="error.description">
                <div class="text">{{ translate('tickets.required_field') }}</div>
            </div>
            <div class="textarea">
                <textarea v-model.trim="description" :placeholder="translate('tickets.description.placeholder')" :class="{ error: error.description }"></textarea>
            </div>
        </div>

        <!-- Email -->
        <div class="item email">
            <div class="label">{{ translate('tickets.email') }}</div>
            <div class="label-error" v-if="error.email">
                <div class="text">{{ error.invalidEmail ? translate('tickets.invalid_email') : translate('tickets.required_field') }}</div>
            </div>
            <div class="input">
                <input v-model.trim="email" :placeholder="translate('tickets.email_placeholder')" :class="{ error: error.email }" />
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import store from '@/store'

export default {
    name: 'PopupTicket',
    props: {},
    data() {
        return {
            options: {
                labelling: {
                    key: 'labelling',
                    value: i18n.t('tickets.options.labelling')
                },
                library: {
                    key: 'library',
                    value: i18n.t('tickets.options.library')
                },
                'tasks-and-records': {
                    key: 'tasks-and-records',
                    value: i18n.t('tickets.options.tasks-and-records')
                },
                printer: {
                    key: 'printer',
                    value: i18n.t('tickets.options.printer')
                },
                audits: {
                    key: 'audits',
                    value: i18n.t('tickets.options.audits')
                },
                incidents: {
                    key: 'incidents',
                    value: i18n.t('tickets.options.incidents')
                },
                'users-and-login': {
                    key: 'users-and-login',
                    value: i18n.t('tickets.options.users-and-login')
                },
                tablet: {
                    key: 'tablet',
                    value: i18n.t('tickets.options.tablet')
                }
            },
            selectedTool: -1,
            description: '',
            email: '',
            location: '',
            location_id: '',
            blockLocation: false,
            name: '',
            error: {
                location: false,
                name: false,
                tool: false,
                description: false,
                email: false,
                invalidEmail: false
            }
        }
    },
    computed: {
        isApp() {
            return this.$store.getters['getIsApp']
        }
    },
    methods: {
        openHelpCenter() {
            if (this.isApp) {
                cordova.InAppBrowser.open('https://help.andyapp.io/', '_system')
            } else {
                window.open('https://help.andyapp.io/', '_blank')
            }
        },
        translate(string) {
            return i18n.t(string)
        },
        validateForm() {
            let valid = true

            // validate location
            if (this.location == '') {
                this.error.location = true
                valid = false
            } else {
                this.error.location = false
            }

            // validate name
            if (this.name == '') {
                this.error.name = true
                valid = false
            } else {
                this.error.name = false
            }

            // validate tool
            if (this.selectedTool == -1) {
                this.error.tool = true
                valid = false
            } else {
                this.error.tool = false
            }

            // description
            if (this.description == '') {
                this.error.description = true
                valid = false
            } else {
                this.error.description = false
            }

            // reset error invalid email
            this.error.invalidEmail = false

            // email
            if (this.email == '') {
                this.error.email = true
                valid = false
            } else {
                this.error.email = false

                if (!validEmail(this.email)) {
                    this.error.email = true
                    this.error.invalidEmail = true
                    valid = false
                } else {
                    this.error.email = false
                    this.error.invalidEmail = false
                }
            }

            let result = {
                location_id: this.location_id,
                location: this.location,
                employee_id: this.name,
                tool: this.selectedTool,
                description: this.description,
                email: this.email
            }

            return valid ? result : valid
        }
    },
    created() {
        // check if location is logged
        let location = store.getters['loginUser/getLocalLocation']
        if (Object.keys(location).length > 0) {
            this.location_id = location.id
            this.location = location.name
            this.blockLocation = true
        }
    }
}
</script>

<style lang="scss">
.popupTicket {
    @include display-flex();
    @include justify-content(flex-start);
    @include align-items();
    @include flex-wrap(wrap);
    padding: 0 50px !important;

    .item {
        margin-bottom: 30px;
        width: 50%;

        .label {
            @include font-size(m);
            font-family: $text;
            color: $neutro-s90;
        }

        .label-error {
            @include display-flex();
            @include justify-content(flex-start);
            @include align-items();
            @include flex-wrap(wrap);
            height: 40px;
            width: auto;

            .text {
                @include border-radius(4px);
                @include background($color: $error-light);
                @include font-size(ml);
                color: $error-s70;
                font-family: $conden;
                padding: 6px 9px;
                margin: 0;
            }
        }

        &.helpcenter_tooltip {
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-wrap(wrap);
            width: 100%;

            .info {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include flex-wrap(wrap);
                @include background($color: $info-t30);
                @include border-radius(4px);
                box-shadow: 0px 0px 6px rgba(221, 221, 221, 0.75);
                width: auto;
                padding: 9px 12px;

                .leftPart {
                    min-height: 48px;
                    height: auto;
                    padding-right: 9px;
                    .text {
                        @include font-size(ml);
                        font-family: $conden;
                        color: $info-s70;
                    }
                }

                .rightPart {
                    .button {
                        @include interaction();
                        @include background($color: $neutro-t60);
                        @include font-size(ml);
                        @include border-radius(4px);
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);
                        font-family: $conden;
                        color: $info-s90;
                        height: 40px;
                        padding: 8px 12px;
                        @include bgHover($neutro-t60);
                    }
                }
            }
        }

        &.location,
        &.name {
            .input {
                input {
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $neutro-s90;
                    height: 40px;
                    width: 280px;

                    &::placeholder {
                        font-family: $text;
                        color: $neutro-s30;
                    }
                }
            }
        }

        &.tool {
            width: 100%;

            .selector {
                select {
                    @include background($image: img('down_caret_neutro_s90.svg'), $position: center right 15px, $size: 10px);
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $neutro-s90;
                    height: 40px;
                    width: 280px;

                    option {
                        @include font-size(m);
                        font-family: $text-bold;
                        color: $neutro-s90;
                    }
                }
            }
        }

        &.description {
            width: 100%;

            .label {
            }

            .sublabel {
                @include font-size(s);
                font-family: $text;
                color: $neutro-s70;
            }

            .textarea {
                textarea {
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $neutro-s90;
                    height: 90px;
                    width: 694px;

                    &::placeholder {
                        font-family: $text;
                        color: $neutro-s30;
                    }
                }
            }
        }

        &.email {
            width: 100%;
            .input {
                input {
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $neutro-s90;
                    height: 40px;
                    width: 280px;

                    &::placeholder {
                        font-family: $text;
                        color: $neutro-s30;
                    }
                }
            }
        }

        input,
        select,
        textarea {
            &.error {
                @include border-radius(4px);
                border: 1px solid $error-dark;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .helpcenter_tooltip {
            .info {
                max-width: none !important;
                .leftPart {
                    @include display-flex();
                    @include justify-content(flex-start);
                    @include align-items();
                    @include flex-wrap(wrap);
                    height: auto;
                    width: 70%;
                    .text {
                        height: auto;
                    }
                }

                .rightPart {
                    height: auto;
                    width: 30%;
                    .button {
                        width: auto;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        .helpcenter_tooltip {
            .info {
                .leftPart {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    width: 100%;
                    margin: 5px 0 10px 0;
                }
                .rightPart {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    width: 100%;
                    margin-bottom: 5px;
                    .button {
                        max-width: 300px;
                    }
                }
            }
        }
    }
}
</style>
