import Vue from 'vue'
import { Api } from '@/core/api.js'

import localforage from 'localforage'
const api = new Api()

const db_categories = localforage.createInstance({ name: 'alexdb', storeName: 'product_categories' })
const db_products = localforage.createInstance({ name: 'alexdb', storeName: 'products' })
const db_collections = localforage.createInstance({ name: 'alexdb', storeName: 'product_collections' })
const db_printlogs = localforage.createInstance({ name: 'alexdb', storeName: 'product_printlogs' })
const db_error_logs = localforage.createInstance({ name: 'alexdb', storeName: 'product_print_error_logs' })
const db_printlogs_android = localforage.createInstance({ name: 'alexdb', storeName: 'product_printlogs_android' })
const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        labels: {},
        labelsOrdered: {},
        categories: {},
        predefined: {},
        resume: {},
        printed: {},
        last_label_update: typeof localStorage.last_label_update !== 'undefined' ? parseInt(localStorage.last_label_update) : false,
        printLogsAndroid: {},
        isPrinting: false,
        portraitDefaultTab: false
    }
}

// initial state
const state = getDefaultState()

const loadLocalForageInformation = async () => {
    state.portraitDefaultTab = await db_login.getItem('label_portrait_default_tab')
}

loadLocalForageInformation()

// getters
const getters = {
    getCollections: (state, getters, rootState) => {
        return state.predefined
    },

    getCategories: (state, getters, rootState) => {
        return state.categories
    },

    getSortedCategories: (state, getters, rootState) => {
        return Object.values(state.categories).sort((a, b) => parseInt(a.order) - parseInt(b.order))
    },

    getSelectedCategory: (state, getters, rootState) => {
        for (var i in state.categories) {
            if (state.categories[i].selected) return state.categories[i]
        }
        return false
    },

    getResume: (state, getters, rootState) => {
        return state.resume
    },

    getLabelsPrinted: (state, getters, rootState) => {
        return state.printed
    },

    getLabels: (state, getters, rootState) => {
        return state.labels
    },
    getLabelsOrdered: (state, getters, rootState) => {
        return state.labelsOrdered
    },

    getLabelStates: (state, getters, rootState) => id => {
        var states = {}
        for (var i in state.labels) {
            var label = state.labels[i]
            for (var j in label.states) {
                if (!Object.keys(states)[label.states[j].id]) {
                    states[label.states[j].id] = label.states[j]
                }
            }
        }
        if (typeof id !== 'undefined') return states[id]
        return states
    },

    getLabel: (state, getters, rootState) => id => {
        return state.labels[id]
    },

    getImagesDownloaded: (state, getters, rootState) => {
        return state.last_label_update
    },

    getPrintLogsAndroid: (state, getters, rootState) => {
        return state.printLogsAndroid
    },

    getIsPrinting: (state, getters, rootState) => {
        return state.isPrinting
    },

    getPortraitDefaultTab: (state, getters, rootState) => {
        return state.portraitDefaultTab
    }
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    getCountSync(context) {
        return new Promise((resolve, reject) => {
            var count = 0
            db_printlogs
                .iterate(function(value, key, iterationNumber) {
                    count++
                })
                .then(function() {
                    db_error_logs
                        .iterate(function(value, key, iterationNumber) {
                            count++
                        })
                        .then(function() {
                            // db_printlogs_android
                            //     .iterate(function(value, key, iterationNumber) {
                            //         count++
                            //     })
                            //     .then(function() {
                            //     })
                            resolve(count)
                        })
                })
        })
    },

    resetState({ commit }) {
        commit('resetState')
    },

    loadLabels(context, params) {
        var count = 0
        var categories = {}
        var labels = {}
        var collections = {}
        log('loadLabels -> REFRESH: ', context.rootGetters['getRefresh'].products)
        log('loadLabels -> Connection: ', fnCheckConnection())
        if (context.rootGetters['getRefresh'].products && fnCheckConnection()) {
            return api.get('labels', {}).then(function(response) {
                var data = format(response.data.categories)
                var dataDefault = format(response.data.default)
                context.commit('setCategories', data.categories)
                context.commit('setLabels', data.labels)
                context.commit('setPredefined', dataDefault.categories)
                context.commit('setRefreshProducts', false, { root: true })
                // context.commit('setPredefined', dataDefault.categories);

                db_categories.clear()
                db_products.clear()
                db_collections.clear()

                _.forEach(_.values(data.categories), function(value, key) {
                    db_categories.setItem(value.id, value)
                })

                _.forEach(_.values(data.labels), function(value, key) {
                    db_products.setItem(value.id, value)
                })

                _.forEach(_.values(dataDefault.categories), function(value, key) {
                    db_collections.setItem(value.id, value)
                })

                if (context.rootGetters.getIsApp && parseInt(response.data.update) == 1) {
                    context.dispatch('processLabelsImage', data.labels)
                }

                // context.rootState.refresh.products = false
            })
        } else if (context.rootGetters['getRefresh'].products) {
            db_categories
                .iterate(function(value, key, iterationNumber) {
                    categories[key] = value
                    count++
                })
                .then(function() {
                    return db_products.iterate(function(value, key, iterationNumber) {
                        labels[key] = value
                        count++
                    })
                })
                .then(function() {
                    return db_collections.iterate(function(value, key, iterationNumber) {
                        collections[key] = value
                        count++
                    })
                })
                .then(function() {
                    if (_.size(_.keys(categories)) > 0) {
                        context.commit('setCategories', categories)
                    }
                    if (_.size(_.keys(labels)) > 0) {
                        context.commit('setLabels', labels)
                    }
                    if (_.size(_.keys(collections)) > 0) {
                        context.commit('setPredefined', collections)
                    }
                })
        }
    },

    loadLabelsResume(context, params) {
        return api.get('labels/resume', {}).then(function(response) {
            var data = formatResume(response.data)
            context.commit('setResume', data)
        })
    },

    loadLabelsPrinted(context, params) {
        return api.post('labels/printed', params).then(function(response) {
            var data = response.data
            context.commit('setLabelsPrinted', data)
        })
    },

    sendLabelPrint(context, params) {
        if (fnCheckConnection()) {
            return api.post('labels/print', params).then(function(response) {
                if (response) {
                    return true
                } else {
                    params.current_print_time = moment().format('YYYY-MM-DD HH:mm:ss')
                    db_printlogs.setItem(moment().valueOf(), params)
                    return false
                }
            })
        } else {
            params.current_print_time = moment().format('YYYY-MM-DD HH:mm:ss')
            db_printlogs.setItem(moment().valueOf(), params)
        }
    },

    uploadFailedLabelsPrint(context, labels) {
        return new Promise((resolve, reject) => {
            // console.log('uploadFailedLabelsPrint', labels, labels.length)
            if (labels.length == 0) resolve()
            else {
                var numLabelsToUpload = 10
                var labelsUpload = labels.slice(0, numLabelsToUpload)
                if (fnCheckConnection()) {
                    // console.log('uploading...', labelsUpload)
                    api.post('labels/print/multiple', {
                        data: JSON.stringify(labelsUpload)
                    }).then(function(response) {
                        if (!response || !response.status) {
                            _.forEach(_.values(labelsUpload), function(value, key) {
                                value.error_uploading = true
                                db_printlogs.setItem(moment(value.current_print_time).valueOf() + moment().milliseconds(), value)
                            })
                        }
                        labels = labels.slice(numLabelsToUpload)
                        context.dispatch('uploadFailedLabelsPrint', labels).then(() => {
                            resolve()
                        })
                    })
                } else {
                    _.forEach(_.values(labelsUpload), function(value, key) {
                        value.error_uploading = true
                        db_printlogs.setItem(moment(value.current_print_time).valueOf() + moment().milliseconds(), value)
                    })
                    labels = labels.slice(numLabelsToUpload)
                    context.dispatch('uploadFailedLabelsPrint', labels).then(() => {
                        resolve()
                    })
                }
            }
        })
    },

    sendFailedLabelsPrint(context, params) {
        // console.log('sendFailedLabelsPrint')
        if (fnCheckConnection()) {
            var aLabelsPrint = []
            db_printlogs
                .iterate(function(value, key, iterationNumber) {
                    aLabelsPrint.push(value)
                })
                .then(function() {
                    // console.log(aLabelsPrint)
                    db_printlogs.clear()
                    return context.dispatch('uploadFailedLabelsPrint', aLabelsPrint)
                })
        } else {
            return true
        }
    },

    addPrintError(context, params) {
        if (fnCheckConnection()) {
            return api.post(`labels/print/error`, { error: params.error, platform: params.platform, version: params.version })
        } else {
            const sNow = moment().format('YYYY-MM-DD HH:mm:ss')
            db_error_logs.setItem(sNow, {
                error: params.error,
                created_date: sNow,
                platform: params.platform,
                version: params.version
            })
            return true
        }
    },

    sendFailedPrintErrors(context) {
        if (fnCheckConnection()) {
            const aErrors = []

            return db_error_logs
                .iterate(function(value, key, iIndex) {
                    aErrors.push(value)
                })
                .then(function() {
                    if (aErrors.length == 0) {
                        return true
                    }

                    return api.post('labels/print/multiple/error', { data: JSON.stringify(aErrors) }).then(function(response) {
                        return db_error_logs.clear().then(function() {
                            return true
                        })
                    })
                })
        }
    },

    sync(context, params) {
        if (fnCheckConnection()) {
            return api.get('labels/sync', {}).then(function(response) {
                return response.data
            })
        }
        return false
    },

    processLabelsImage(context, params) {
        var aImages = []
        var aLocalImages = []
        // console.log('processLabelsImage -> ', context.getters['getImagesDownloaded'])
        for (var k in params) {
            var label = params[k]
            if (label.image && label.image != 'false' && label.image != 'null') {
                if (!context.getters['getImagesDownloaded'] || (label.last_update && context.getters['getImagesDownloaded'] < moment(label.last_update).valueOf())) {
                    // console.log('processLabelsImage -> label: ', label)
                    // console.log('processLabelsImage -> label.last_update: ', moment(label.last_update).valueOf())
                    // console.log('processLabelsImage -> getImagesDownloaded: ', context.getters['getImagesDownloaded'])
                    // console.log('DOWNLOAD IMAGE')
                    aImages.push(label)
                } else {
                    // console.log('NO DOWNLOAD IMAGE, VALIDATE ON LOCAL')
                    aLocalImages.push(label)
                }
            } else {
                // console.log('NO PRODUCT IMAGE')
            }
        }

        return context.dispatch('queueDownloadImages', aImages).then(() => {
            return context.dispatch('queueLocalImages', aLocalImages)
        })
    },

    queueLocalImages(context, queue) {
        return new Promise((resolve, reject) => {
            if (queue.length > 0) {
                var label = Object.assign({}, queue.pop())
                var imageName = label.image.split('/')
                imageName = imageName[imageName.length - 1]
                // console.log('queueDownloadImage -> imageName: ', imageName)
                window.resolveLocalFileSystemURL(
                    cordova.file.applicationStorageDirectory + 'images/' + imageName,
                    function(fileEntry) {
                        fileEntry.file(
                            function(entry) {
                                // console.log('IMAGEN EXISTE!: ', entry)
                                label.local_image = cordova.file.applicationStorageDirectory + 'images/' + imageName
                                db_products.setItem(label.id, label)
                                context.commit('setLabel', label)
                                return context.dispatch('queueLocalImages', queue)
                            },
                            function(e) {
                                // console.log('E2 Local Images - ', e)
                                return context.dispatch('queueLocalImages', queue)
                            }
                        )
                    },
                    function(e) {
                        // console.log('E1  Local Images - ', e)
                        return context.dispatch('queueLocalImages', queue)
                    }
                )
            } else {
                resolve()
            }
        })
    },

    queueDownloadImages(context, queue) {
        return new Promise((resolve, reject) => {
            if (queue.length > 0) {
                var label = queue.pop()
                var imageName = label.image.split('/')
                imageName = imageName[imageName.length - 1]
                // console.log('queueDownloadImage -> imageName: ', imageName)
                window.resolveLocalFileSystemURL(
                    cordova.file.applicationStorageDirectory + 'images/' + imageName,
                    function(fileEntry) {
                        fileEntry.file(
                            function(entry) {
                                // console.log(entry)
                                return context.dispatch('downloadLabelImage', { uri: label.image, finalPath: cordova.file.applicationStorageDirectory + 'images/' + imageName, label: label }).then(() => {
                                    return context.dispatch('queueDownloadImages', queue)
                                })
                            },
                            function(e) {
                                // console.log('E2 - ', e)
                                return context.dispatch('downloadLabelImage', { uri: label.image, finalPath: cordova.file.applicationStorageDirectory + 'images/' + imageName, label: label }).then(() => {
                                    return context.dispatch('queueDownloadImages', queue)
                                })
                            }
                        )
                    },
                    function(e) {
                        // console.log('E1 - ', e)
                        return context.dispatch('downloadLabelImage', { uri: label.image, finalPath: cordova.file.applicationStorageDirectory + 'images/' + imageName, label: label }).then(() => {
                            return context.dispatch('queueDownloadImages', queue)
                        })
                    }
                )
            } else {
                context.commit('setLastLabelUpdate', moment().valueOf())
                resolve()
            }
        })
    },

    downloadLabelImage(context, labelImage) {
        return new Promise((resolve, reject) => {
            var fileTransfer = new FileTransfer()
            fileTransfer.download(
                labelImage.uri,
                labelImage.finalPath,
                function(entry) {
                    // console.log('Successful download...')
                    // console.log('download complete: ' + entry.toURL())

                    var label = Object.assign(labelImage.label)
                    label.local_image = labelImage.finalPath
                    db_products.setItem(label.id, label)
                    context.commit('setLabel', label)
                    resolve()
                },
                function(error) {
                    log('download error source ' + error.source)
                    log('download error target ' + error.target)
                    log('upload error code' + error.code)

                    resolve()
                },
                null, // or, pass false
                {}
            )
        })
    },

    getPrintLogsAndroid: (context, params) => {
        const labels = {}
        return new Promise((resolve, reject) => {
            db_printlogs_android
                .iterate((value, key, iterationNumber) => {
                    labels[key] = value
                })
                .then(() => {
                    resolve(context.commit('setPrintLogsAndroid', labels))
                })
                .catch(error => {
                    resolve(context.commit('setPrintLogsAndroid', {}))
                })
        })
    },

    getFilteredLogsAndroid: (context, params) => {
        const labels = {}
        return new Promise((resolve, reject) => {
            db_printlogs_android
                .iterate((value, key, iterationNumber) => {
                    // filter by status
                    if (params && params.status && params.status.includes(parseInt(value.status))) {
                        labels[key] = value
                    }
                })
                .then(() => {
                    return resolve(labels)
                })
                .catch(error => {
                    return resolve({})
                })
        })
    },

    addPrintLabelLogAndroid(context, params) {
        return db_printlogs_android.keys().then(keys => {
            let biggestNumber = 0

            keys.forEach(index => {
                index = parseInt(index)
                if (index > biggestNumber) {
                    biggestNumber = index
                }
            })

            const index = (biggestNumber + 1).toString()
            params.id = index
            params.current_print_time = moment().format('YYYY-MM-DD HH:mm:ss')

            db_printlogs_android.setItem(index, params)

            return true
        })
    },

    removePrintLabelLogAndroid(context, key) {
        log('--- removePrintLabelLogAndroid ---', key, typeof key)
        return new Promise((resolve, reject) => {
            db_printlogs_android
                .removeItem(key)
                .then(() => {
                    resolve()
                })
                .catch(error => {
                    reject()
                })
        })
    },

    clearPrintLabelLog(context) {
        return new Promise((resolve, reject) => {
            db_printlogs_android
                .clear()
                .then(() => resolve())
                .catch(error => reject())
        })
    },

    clearQueueItems(context, queue) {
        for (let index in queue) {
            context.commit('removeItemQueue', queue[index].id)
        }
    },

    sendPrintLabelLogAndroid_Queue(context, params) {
        log('--- sendPrintLabelLogAndroid_Queue --- ', Object.assign({}, params))
        return new Promise((resolve, reject) => {
            let aQueueIDs = JSON.parse(JSON.stringify(params.ids))
            let aQueueValues = JSON.parse(JSON.stringify(params.values))
            log('aQueueIDs -> ', aQueueIDs, aQueueIDs.length)
            log('aQueueValues -> ', aQueueValues)
            if (aQueueIDs.length == 0) {
                log('LANZO RESOLVE')
                resolve(aQueueValues)
            } else {
                var key = aQueueIDs.pop()
                db_printlogs_android
                    .getItem(key)
                    .then(value => {
                        value.printed_date = moment().format('YYYY-MM-DD HH:mm:ss')
                        aQueueValues.push(value)
                        db_printlogs_android.removeItem(key).then(() => {
                            context.dispatch('sendPrintLabelLogAndroid_Queue', { ids: aQueueIDs, values: aQueueValues }).then(response => {
                                resolve(response)
                            })
                        })
                    })
                    .catch(err => {
                        context.dispatch('sendPrintLabelLogAndroid_Queue', { ids: aQueueIDs, values: aQueueValues }).then(response => {
                            resolve(response)
                        })
                    })
            }
        })
    },

    sendPrintLabelLogAndroid(context, aQueueIDs) {
        return context.dispatch('sendPrintLabelLogAndroid_Queue', { ids: aQueueIDs, values: [] }).then(labels => {
            return context.dispatch('uploadFailedLabelsPrint', labels)
        })

        // const labels = []

        // db_printlogs_android
        //     .iterate((value, key, iterationNumber) => {
        //         if (aQueueIDs.includes(key)) {
        //             let item = Object.assign({}, value)
        //             item.printed_date = moment().format('YYYY-MM-DD HH:mm:ss')
        //             labels.push(item)
        //             await db_printlogs_android.removeItem(key)
        //         }
        //     })
        //     .then(() => {
        //         return context.dispatch('uploadFailedLabelsPrint', labels)
        //     })
        //     .catch(error => {
        //         return false
        //     })
    },

    resetQueue(context) {
        // if queue has corrupted items (with status 1) update with status 2
        return new Promise((resolve, reject) => {
            db_printlogs_android
                .iterate((value, key, iterationNumber) => {
                    if (parseInt(value.status) == 1) {
                        value.status = 2
                        context.commit('setItemQueue', value)
                    }
                })
                .then(() => {
                    resolve(true)
                })
                .catch(error => {
                    logError(error)
                    reject(false)
                })
        })
    },

    getRelatedLabels(context, params) {
        if (fnCheckConnection()) {
            return api
                .post(`labels/related/fields`, { reference_id: params.reference_id, state_id: params.state_id, format_id: params.format_id })
                .then(response => {
                    return response.data
                })
                .catch(error => {
                    logError(error)
                    return {}
                })
        } else {
            return {}
        }
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setLabels(state, data) {
        // console.log("data");
        // console.log(JSON.stringify(data));
        var ordered = {}
        var aux = Object.values(data)
        aux.sort((a, b) => a.name.localeCompare(b.name, 'es', { numeric: true }))
        for (var i in aux) {
            var lab = aux[i]
            ordered[lab.id] = lab
        }
        // console.log("ordered");
        // console.log(JSON.stringify(ordered));
        state.labelsOrdered = ordered
        state.labels = data
    },

    setLabel(state, label) {
        var labels = Object.assign(state.labels)
        var labelsOrdered = Object.assign(state.labelsOrdered)
        labels[label.id] = label
        labelsOrdered[label.id] = label

        state.labels = labels
        state.labelsOrdered = labelsOrdered
        Vue.set(state, 'labels', Object.assign(labels))
        Vue.set(state, 'labelsOrdered', Object.assign(labelsOrdered))
    },

    setResume(state, data) {
        state.resume = data
    },

    setLabelsPrinted(state, data) {
        state.printed = data
    },

    setCategories(state, data) {
        state.categories = data
    },
    setPredefined(state, data) {
        state.predefined = data
    },

    selectCategory(state, category) {
        if (category == false || category == 'false') {
            for (var k in state.categories) {
                var cat = state.categories[k]
                cat.selected = false
            }
        } else {
            for (var k in state.categories) {
                var cat = state.categories[k]
                if (cat.id == category.id) {
                    cat.selected = true
                } else {
                    cat.selected = false
                }
            }
        }
        Vue.set(state, 'categories', { ...state.categories })
    },

    selectPredefined(state, predef) {
        if (predef == false) {
            for (var k in state.predefined) {
                var cat = state.predefined[k]
                cat.selected = false
            }
        } else {
            for (var k in state.predefined) {
                var cat = state.predefined[k]
                if (cat.id == predef.id) {
                    cat.selected = true
                } else {
                    cat.selected = false
                }
            }
        }
        Vue.set(state, 'predefined', { ...state.predefined })
    },

    referenceStateSelected(state, data) {
        state.predefined[data.collectionID].references[data.referenceID].states[data.stateID].selected = data.selected
    },

    referenceStateCompleted(state, data) {
        state.predefined[data.collectionID].references[data.referenceID].states[data.stateID].completed = true
    },

    referenceStateCurrentQuantity(state, data) {
        state.predefined[data.collectionID].references[data.referenceID].states[data.stateID].currentQuantity = data.currentQuantity
    },

    referenceStateReset(state, data) {
        for (let collectionID in state.predefined) {
            for (let referenceID in state.predefined[collectionID].references) {
                let reference = state.predefined[collectionID].references[referenceID]

                for (let stateID in reference.states) {
                    let state = reference.states[stateID]

                    // remove completed, selected and current quantity
                    delete state.completed
                    delete state.selected
                    delete state.currentQuantity
                }
            }
        }
    },

    setLastLabelUpdate(state, value) {
        log('setLastLabelUpdate -> value: ', value)
        state.last_label_update = value
        localStorage.setItem('last_label_update', value)
    },
    setPrintLogsAndroid(state, labels) {
        state.printLogsAndroid = labels
        Vue.set(state, 'printLogsAndroid', { ...state.printLogsAndroid })
    },
    setIsPrinting(state, value) {
        state.isPrinting = value
        Vue.set(state, 'isPrinting', value)
    },
    setItemQueue(state, value) {
        state.printLogsAndroid[value.id] = value
        Vue.set(state, 'printLogsAndroid', { ...state.printLogsAndroid })
        db_printlogs_android.setItem(value.id, value)
    },
    removeItemQueue(state, id) {
        const logsAndroid = { ...state.printLogsAndroid }
        delete logsAndroid[id]
        Vue.set(state, 'printLogsAndroid', logsAndroid)
        db_printlogs_android.removeItem(id)
    },
    updateQueueStatus(state, params) {
        for (let index in params.queue) {
            let queueItem = params.queue[index]
            queueItem.status = params.status
            state.printLogsAndroid[queueItem.id] = queueItem
            db_printlogs_android.setItem(queueItem.id, queueItem)
        }
        Vue.set(state, 'printLogsAndroid', { ...state.printLogsAndroid })
    },
    setPortraitDefaultTab(state, value) {
        state.portraitDefaultTab = value
        Vue.set(state, 'portraitDefaultTab', value)
        db_login.setItem('label_portrait_default_tab', value)
    }
}

function format(raw) {
    var categories = {}
    var labels = {}

    var found = false

    for (var k in raw) {
        var cat = raw[k]
        cat.visible = false
        cat.selected = false
        if (!found) {
            // cat.selected = true;
            found = true
        }
        categories[cat.id] = cat

        for (var i in cat.references) {
            var label = cat.references[i]
            label.category_id = cat.id
            labels[label.id] = label
        }
    }

    return { categories: categories, labels: labels }
}

function formatResume(raw) {
    var resume = {}

    var today = moment().startOf('day')

    for (var i in raw) {
        var oDay = {
            date: moment(i * 1000),
            isToday:
                moment(i * 1000)
                    .startOf('day')
                    .unix() == today.unix(),
            product: {}
        }

        resume[i] = oDay

        for (var j in raw[i]) {
            var label = raw[i][j]

            var oProduct = {
                reference_id: label.reference_id,
                id: label.id,
                name: label.name,
                quantity: label.quantity,
                created_date: moment(label.created_date * 1000),
                end_date: moment(label.end_date * 1000),
                alert: label.alert,
                showing: true,
                toExpire: false,
                expired: false
            }

            if (oDay.isToday) {
                oProduct = calculate_expiration(oProduct)
            }

            resume[i].product[label.id] = oProduct
        }
    }

    return resume
}

function calculate_expiration(oProduct) {
    var now = moment()
    if (oProduct.end_date.isBefore(now)) {
        // console.log("before");

        if (now.diff(oProduct.end_date, 'hours') > 2) {
            // console.log("NOT SHOW NOT NOT");
            oProduct.showing = false
        } else {
            // console.log("EXPIRED");
            oProduct.expired = true
        }
    } else if (oProduct.end_date.isAfter(now)) {
        // console.log("after");
        if (oProduct.end_date.diff(now, 'hours') < 1) {
            // console.log("TO EXPIRE");
            oProduct.toExpire = true
        }
    }

    return oProduct
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
