// Import
import i18n from '@/i18n'
import Vue from 'vue'
import store from '@/store'
import { Api } from '@/core/api.js'

import { FORMAT } from '@/constants'

import localforage from 'localforage'
const api = new Api()

var db_issues = localforage.createInstance({
    name: 'alexdb',
    storeName: 'issues'
})
var db_templates = localforage.createInstance({
    name: 'alexdb',
    storeName: 'issues_templates'
})

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        stats: {
            alerts: {},
            activity: {}
        },
        items: {},
        templates: {},
        date: false,
        type: 4,
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: false,
            has_state: true,
            has_timing: false,
            has_mandatory: false,
            is_autosave: true,
            can_create: true,
            item_search: false,
            has_calendar: true,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: true,
                state: true,
                inPast: true,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK, FORMAT.RESOURCE],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE, FORMAT.LINK, FORMAT.RESOURCE],
                can_pospone: false,
                rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: true,
                has_periodicity: false,
                has_score: false
            }
        }
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    // Commons
    getAll: (state, getters, rootState) => {
        return state.items
    },
    getById: (state, getters, rootState) => id => {
        return id ? state.items[id] : false
    },
    getConfig: (state, getters, rootState) => {
        return { ...state.config }
    },
    getTemplates: (state, getters, rootState) => {
        return state.templates
    },
    getSubtasks: (state, getters, rootState) => (check, task) => {
        let tasks = check ? state.items[check].items : {}
        let subtask = []

        Object.keys(tasks).map(function(item_id, i) {
            var item = tasks[item_id]
            if (item.parent_id == task) {
                subtask.push(item.item_id)
            }
        })

        return subtask
    },

    getAllStats: (state, getters, rootState) => {
        return state.stats
    },
    getStats: (state, getters, rootState) => wich => {
        // console.log('ISSUES - getStats ->', state.stats[wich])
        return state.stats[wich]
    }

    // Issues
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    resetState({ commit }) {
        commit('resetState')
    },

    // Common
    loadAll({ state, context, commit, dispatch, rootState }, params) {
        // console.log("loadAll");
        // console.log(params);
        let date = params.date.length ? params.date[2] : 'month'

        if (fnCheckConnection()) {
            // If Internet, call API
            let url = 'issues/' + date + '/'
            if (date == 'range') {
                url = 'issues/' + params.date[0] + '/' + params.date[1]
            }

            // console.log("---- CALL API");
            return api.get(url, {}).then(function(response) {
                _.forEach(_.values(response.data), function(value, key) {
                    db_issues.setItem(value.id, JSON.stringify(value))
                })
                commit('setDate', params.date)
                dispatch('format', { data: response.data })
                dispatch('calcScore')
            })
        } else {
            // console.log("---- CALL OFFLINE DB");
            var items = []
            var count = 0
            db_issues
                .iterate(function(value, key, iterationNumber) {
                    items.push(JSON.parse(value))
                    count++
                })
                .then(function() {
                    if (count > 0) {
                        // console.log(items);
                        commit('setDate', params.date)
                        dispatch('format', { data: items })
                        dispatch('calcScore')
                    }
                })
        }
    },

    addIssue(context, params) {
        var oData = { template: params.tpl_id }
        if (params.checklist_ref) {
            context.dispatch('calcScore', params.checklist_ref)

            oData.checklist_ref = params.checklist_ref

            let check = context.getters.getById(params.checklist_ref)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            oData.resume = JSON.stringify(resume)
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        return api.post('checklist/add', oData).then(function(response) {
            var items = { ...state.items }
            var reference = { checklist: Object.values(response.data)[0].id, type: Object.values(response.data)[0].type, status: Object.values(response.data)[0].status }
            var item = { ...items[params.checklist_ref].items[params.item_ref] }
            item.reference = reference
            items[params.checklist_ref].items[params.item_ref] = item
            Vue.set(state, 'items', items)
            return response.data
        })
    },
    comment(context, params) {
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        var sended = {
            checklist: params.check,
            task: params.answer,
            message: params.message,
            resume: JSON.stringify(resume)
        }
        if (typeof params.file !== 'undefined') sended.file = params.file

        return api.post('checklist/task/comment', sended).then(function(response) {
            var returned = {
                check: params.check,
                answer: params.answer,
                message: response.data.message
            }
            if (typeof response.data.image !== 'undefined') {
                returned.image = response.data.image
            }

            // console.log(returned);
            return context.commit('setComment', returned)
        })
    },

    validate(context, params) {
        context.commit('setValidation', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date
        })
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/validate', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                resume: JSON.stringify(resume)
            })
            .then(function(response) {})
    },
    unvalidate(context, params) {
        context.commit('setUnvalidation', {
            check: params.check,
            answer: params.answer
        })
        context.commit('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/unvalidate', {
                checklist: params.check,
                task: params.answer,
                resume: JSON.stringify(resume)
            })
            .then(function(response) {})
    },

    complete(context, params) {
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.checklist)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/complete', {
                checklist: params.checklist,
                complete_date: params.complete_date,
                values: JSON.stringify(params.values),
                resume: JSON.stringify(resume)
            })
            .then(function(response) {
                return response
            })
    },

    uncomplete(context, params) {
        return api
            .post('checklist/uncomplete', {
                checklist: params.checklist
            })
            .then(function(response) {
                // console.log(response)
                return response
            })
    },

    loadById(context, params) {
        return api.get('checklist/id/' + params.id, {}).then(function(response) {
            context.dispatch('format', { data: response.data })
            context.dispatch('calcScore', params.id)
        })
    },

    loadTemplates(context, params) {
        var templates = []
        var count = 0

        if (context.rootState.refresh.templates && fnCheckConnection()) {
            return api.get('checklist/templates/' + state.type, {}).then(function(response) {
                context.commit('setTemplates', { data: response.data })

                _.forEach(_.values(response.data), function(value, key) {
                    db_templates.setItem(value.id, value)
                })

                context.rootState.refresh.templates = false
            })
        } else {
            db_templates
                .iterate(function(value, key, iterationNumber) {
                    if (value.type == state.type) {
                        templates.push(value)
                        count++
                    }
                })
                .then(function() {
                    if (count > 0) {
                        context.commit('setTemplates', { data: templates })
                    }
                })
        }
    },

    reload() {
        store.dispatch('format', { data: checklist })
        store.dispatch('calcScore')
    },

    update(context, params) {
        var oData = { checklist: params.check }
        if (params.title) oData.title = params.title
        if (params.status) oData.status = params.status
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }

        return api.post('checklist/update', oData).then(function(response) {
            if (params.title) {
                context.commit('setChecklistProp', {
                    check: oData.checklist,
                    prop: 'title',
                    value: oData.title
                })
            }
            if (params.status) {
                context.commit('setChecklistProp', {
                    check: oData.checklist,
                    prop: 'status',
                    value: oData.status
                })
            }
            if (params.emp_ref) {
                context.commit('setChecklistProp', {
                    check: oData.checklist,
                    prop: 'employee_ref',
                    value: oData.employee_ref
                })
            }
        })
    },

    // Common but may change in other tools
    answer(context, params) {
        if (fnCheckConnection()) {
            let value = params.value
            let subtask = context.getters.getSubtasks(params.check, params.answer)

            // In case of image or signature type, JSON-parse the value
            if (state.config.item.itemsImage.includes(params.answer_type)) {
                value = JSON.stringify(value)
            }

            context.commit('setAnswer', {
                check: params.check,
                answer: params.answer,
                emp: params.emp,
                date: params.date,
                value: params.value
            })
            console.log(params)
            context.dispatch('calcScore', params.check)

            let check = context.getters.getById(params.check)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            return api
                .post('checklist/task/answer', {
                    checklist: params.check,
                    task: params.answer,
                    employee: params.emp,
                    complete_date: params.date,
                    value: value,
                    resume: JSON.stringify(resume)
                })
                .then(function(response) {
                    if (!response.status) {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                        context.dispatch('calcScore', params.check)
                    }

                    return response
                })
        } else {
            context.commit('setReset', { check: params.check, answer: params.answer })
            return { status: false }
        }
    },

    apply(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date == 0 ? params.date : false,
            apply: params.apply
        })

        context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function(item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/apply', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                apply: params.apply,
                resume: JSON.stringify(resume)
            })
            .then(function(response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer })
                }
            })
    },

    applyrecurrent(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        // apply
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.apply == 0 ? params.date : false,
            apply: params.apply
        })

        // calculate score
        context.dispatch('calcScore', params.check)

        // reset child items
        if (subtask.length > 0) {
            Object.keys(subtask).map(function(item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        // calculate score

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/applyrecurrent', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                apply: params.apply,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function(response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer })
                }
            })
    },

    reset(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        context.commit('setReset', {
            check: params.check,
            answer: params.answer
        })
        context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function(item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/reset', {
                checklist: params.check,
                task: params.answer,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function(response) {})
    },
    add(context, params) {
        var type = context.state.type
        var oData = { template: params.tpl_id }
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        context.dispatch('calcScore', params.checklist_ref)

        let check = context.getters.getById(params.checklist_ref)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        oData.resume = JSON.stringify(resume)

        return api.post('checklist/add', oData).then(function(response) {
            context.dispatch('format', { data: response.data })
            context.dispatch('calcScore', Object.keys(response.data)[0])
            return response.data[Object.keys(response.data)[0]]
        })
    },
    delete(context, params) {
        var oData = {
            checklist: params.check,
            employee: params.emp,
            status: -1
        }
        if (params.ref_item) {
            oData['ref_item'] = params.ref_item
        }

        return api.post('checklist/update', oData).then(function(response) {
            return response
        })
    },

    loadStats(context, params) {
        context.commit('resetStats', {})
        let config = state.config
        var currentDate = moment(params.date)
        var items = state.items
        var states = params.states
        var left_states = {}
        var new_issues = 0

        // SET ALERTS --------------------------------------------------------------
        // --> Left state
        // Si tiene ESTADOS hacer la alerta, sino no
        if (config.has_state && states) {
            let limitDate = moment(currentDate).subtract(30, 'days')
            let state_searched = []

            // Get searched states
            Object.keys(states).map(function(state_id, i) {
                var state = states[state_id]

                if (state.alert) {
                    state_searched.push(state.id)
                    left_states[state.id] = { value: 0, name: state.name }
                }
            })

            // Search issues with those states
            Object.keys(items).map(function(item_id, i) {
                var item = items[item_id]

                if (state_searched.includes(item.status) && moment(item.business_date).isBetween(limitDate, currentDate, 'day', [])) {
                    left_states[item.status].value++
                }
            })

            Object.keys(left_states).map(function(item_id, i) {
                var st = left_states[item_id]
                log(st)
                if (parseInt(st.value) > 0) {
                    st.state = 'info'
                    st.name = i18n.tc('home.issues.left_state', st.value) + " '" + st.name + "'"
                    st.route = 'Issues'
                    context.commit('setStats', {
                        where: 'alerts',
                        key_name: st.name.trim().toLowerCase(),
                        values: st
                    })
                }
            })
        }

        // SET ACTIVITY ------------------------------------------------------------

        // -- Created Issues
        Object.keys(items).map(function(item_id, i) {
            var item = items[item_id]

            if (item.business_date == currentDate.format('YYYY-MM-DD')) {
                new_issues++
            }
        })

        if (new_issues > 0) {
            context.commit('setStats', {
                where: 'activity',
                key_name: 'created',
                values: {
                    name: i18n.tc('home.activity.issues_generated', new_issues),
                    value: new_issues
                }
            })
        }
    },

    format({ state, context, commit, dispatch, rootState }, params = {}) {
        // var append = typeof payload.append != "undefined" ? payload.append : false;

        var aItems = {}
        // if (append) {
        //   var aItems = state.items;
        // }

        Object.keys(params.data).map(function(item_id, i) {
            var list = params.data[item_id]

            // Timezone set
            list.business_date = list.business_date ? (moment.isMoment(list.business_date) ? list.business_date : moment(list.business_date * 1000)) : false
            list.created_date = list.created_date ? (moment.isMoment(list.created_date) ? list.created_date : moment(list.created_date * 1000)) : false
            list.complete_date = list.complete_date ? (moment.isMoment(list.complete_date) ? list.complete_date : moment(list.complete_date * 1000)) : false

            list.def = state.config
            list.is_visible = true

            // Loop for all items
            Object.keys(list.items).map(function(item_id, i) {
                var item = list.items[item_id]

                // Timezone set
                item.complete_date = item.complete_date ? (moment.isMoment(item.complete_date) ? item.complete_date : moment(item.complete_date * 1000)) : false
                if (item.review) {
                    item.review['complete_date'] = item.review.complete_date ? (moment.isMoment(item.review.complete_date) ? item.review.complete_date : moment(item.review.complete_date * 1000)) : false
                }

                // If type image - Split value to array
                if (state.config.item.itemsImage.includes(item.type)) {
                    item.value = item.value ? item.value.split(',') : item.value
                }

                // console.log(item.config)
                item.config = typeof item.config === 'string' ? JSON.parse(item.config) : item.config

                list.items[item_id] = { ...item }
                Vue.set(list.items, item_id, { ...item })
            })

            aItems[list.id] = list
        })

        // state.items = aItems;
        // Vue.set(state, "items", { ...aItems });
        commit('setFormat', { items: aItems })
    },
    calcScore({ state, context, commit, dispatch, rootState }, id) {
        var aItems = state.items

        Object.keys(aItems).map(function(item_id, i) {
            var list = aItems[item_id]
            if (typeof id == 'undefined' || item_id == id) {
                var past_score = list.score ? list.score : 0
                if (typeof id !== 'undefined') {
                    list.def = state.config
                    list.uncompleted = 0
                    list.completed = 0
                    list.unanswered = 0
                    list.total = 0
                    list.score = 0
                    list.justify_id = list.justify_id ? list.justify_id : list.justify_msg ? '0' : false
                }

                // Loop for all items
                Object.keys(list.items).map(function(item_id, i) {
                    var item = list.items[item_id]
                    item.is_visible = true
                    item.alerts = false

                    // Subtasks
                    if (typeof item.conditions !== 'undefined' && item.conditions != null && item.conditions) {
                        var conditions = item.conditions
                        var current_show = null
                        var current_order = -1
                        var cond_operator = typeof conditions.operator !== 'undefined' ? conditions.operator : 'AND'
                        for (var key in conditions.values) {
                            var show = false
                            let condition = conditions.values[key]
                            let value = list.items[key] ? list.items[key].value : false
                            item.is_visible = false

                            var operator = condition && condition.operator ? condition.operator : '='
                            var val = condition ? condition.value : false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(list.items[key].type)) && list.items[key] && list.items[key].is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    // Get task type from parent
                                    switch (list.items[key].type) {
                                        //   switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val), value, operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                        case FORMAT.TEMPERATURE:
                                            let aux = value.toString().replace(',', '.')
                                            show = compare(parseInt(aux), val, operator)
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            if (current_show == null) current_show = show
                            else {
                                switch (cond_operator) {
                                    case 'AND':
                                        current_show = current_show && show
                                        break
                                    case 'OR':
                                        current_show = current_show || show
                                        break
                                }
                            }
                            if (show) {
                                current_order = list.items[key].order
                            }
                        }

                        item.is_visible = current_show
                        item.order = parseInt(current_order) + 0.5
                    }

                    // Alerts TODO
                    if (item.alerts_triggers) {
                        let alerts = item.alerts_triggers
                        let value = item.value

                        let oAlert = {}
                        let message = ''
                        let preview = ''
                        let issues = []
                        let classes = []

                        for (var i in alerts) {
                            let alert = alerts[i]
                            let operator = alert.operator ? alert.operator : '='
                            let val = alert.value

                            let show = false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(item.type)) && item.is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val), value, operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                        case FORMAT.TEMPERATURE:
                                            let aux = value.toString().replace(',', '.')
                                            show = compare(parseInt(aux), val, operator)
                                            break
                                        default:
                                            break
                                    }
                                }
                            }

                            if (show && alert.msg) {
                                if (message == '') message = alert.msg
                                else message = message + '<br/>' + alert.msg

                                if (preview == '') preview = alert.msg
                                else preview = preview + ', ' + alert.msg
                            }

                            if (show && alert.tpl) {
                                issues.push(alert.tpl)
                            }
                            if (show && alert.class) {
                                if (alert.class == 'alert') {
                                    alert.class = 'warning'
                                }
                                classes.push(alert.class)
                            }
                        }

                        oAlert = {
                            preview: preview,
                            message: message,
                            issues: issues,
                            class: classes
                        }

                        if (oAlert.message.length > 0 || oAlert.issues.length > 0) {
                            item.alerts = oAlert
                            Vue.set(item, 'alerts', oAlert)
                        }
                    }

                    // Scoring
                    // console.log('item - apply:', item.apply, list.total, list.completed)
                    if (((!item.parent_id && list.def.item.itemsExcluded.indexOf(item.type) == -1) || (item.parent_id && item.is_visible)) && !item.planned_to_date) {
                        switch (item.type) {
                            case FORMAT.YESNO: // SI/NO
                            case FORMAT.YESNONC: // SI/NO/NSNC
                            case FORMAT.CHECK: // CHECKS
                            case FORMAT.TEXT: // TEXTO
                            case FORMAT.PHOTO: // FOTO
                            case FORMAT.NUMBER: // NUMBER
                            case FORMAT.DATE: // DATE
                            case FORMAT.TEXTAREA: // TEXTAREA
                            case FORMAT.MULTIPLE: // MULTIPLE SELECT
                            case FORMAT.TIME: // TIME
                            case FORMAT.DATETIME: // DATETIME
                            case FORMAT.SIGNATURE: // SIGNATURE
                            case FORMAT.TEMPERATURE: // TEMPERATURE
                            case FORMAT.EMAIL: // EMAIL
                            case FORMAT.DOCUMENT: // DOCUMENT
                                if (item.complete_date == false && item.apply) list.unanswered++
                                else {
                                    // Required to be reviewed
                                    if (item.require_review) {
                                        list.completed += item.review ? 1 : 0.5
                                    } else list.completed += 1 // Not required to be reviewed
                                }

                                break

                            default:
                                break
                        }
                        if (![FORMAT.LINK, FORMAT.RESOURCE].includes(item.type)) {
                            list.total++
                        }
                    }
                })
                if (typeof id !== 'undefined') {
                    list.score = Math.round((parseFloat(list.completed) * 100) / parseInt(list.total))
                    if (past_score != -1 && past_score < 100 && list.score >= 100) {
                        dispatch('complete', { checklist: list.id, complete_date: moment().unix(), values: [] })
                        list.complete_date = moment()
                    } else if (past_score != -1 && past_score >= 100 && list.score < 100) {
                        dispatch('uncomplete', { checklist: list.id })
                        list.complete_date = false
                    }
                }

                aItems[list.id] = { ...list }
            }
        })

        // ORDER
        Object.keys(aItems).map(function(item_id, i) {
            let list = JSON.parse(JSON.stringify(aItems[item_id]))
            var items = Object.keys(list.items)
                .sort((a, b) => {
                    return parseFloat(list.items[a].order) - parseFloat(list.items[b].order)
                })
                .reduce((prev, curr, i) => {
                    prev[i] = list.items[curr]
                    return prev
                }, {})

            let aux2 = {}
            for (var k in items) {
                aux2[items[k].item_id] = items[k]
            }

            list.items = aux2
            aItems[item_id] = list
        })

        // Vue.set(state, "items", { ...state.items });
        commit('setScore', { items: aItems })
    },

    // Issues
    loadAllByState({ state, commit, rootState }, params) {
        // console.log("TO DO VUE FILTER");
        // TODO RETURN ISSUES BY STATE (WITHOUT CALLING THE API, ONLY VUE)
        // if (fnCheckConnection()) {
        //   // If Internet, call API
        //   let url = "issues/" + params.state;
        //
        //   return api.get(url, {}).then(function(response) {
        //     _.forEach(_.values(response.data), function(value, key) {
        //       db_issues.setItem(value.id, JSON.stringify(value));
        //     });
        //     dispatch("format", { data: response.data });
        //     dispatch("calcScore");
        //   });
        // } else {
        //   var items = [];
        //   var count = 0;
        //   db_issues
        //     .iterate(function(value, key, iterationNumber) {
        //       items.push(JSON.parse(value));
        //       count++;
        //     })
        //     .then(function() {
        //       if (count > 0) {
        //         dispatch("format", { data: items });
        //         dispatch("calcScore");
        //       }
        //     });
        // }
    },

    loadCheckById(context, params) {
        return api.get('checklist/id/' + params.id, {}).then(function(response) {
            let result = {}

            Object.keys(response.data).map(function(tframe_id, i) {
                var tframe = response.data[tframe_id]

                Object.keys(tframe.templates).map(function(item_id, i) {
                    result = tframe.templates[item_id]

                    // Timezone set
                    result.business_date = result.business_date ? (moment.isMoment(result.business_date) ? result.business_date : moment(result.business_date * 1000)) : false
                    result.created_date = result.created_date ? (moment.isMoment(result.created_date) ? result.created_date : moment(result.created_date * 1000)) : false
                    result.complete_date = result.complete_date ? (moment.isMoment(result.complete_date) ? result.complete_date : moment(result.complete_date * 1000)) : false
                })
            })
            return result
        })
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    // Commons
    setDate(state, date) {
        Vue.set(state, 'date', date)
    },
    setTemplates(state, payload) {
        var templates = {}

        if (payload.data != undefined) {
            Object.keys(payload.data).map(function(tpl_id, i) {
                var tpl = { ...payload.data[tpl_id] }

                tpl.last_update = tpl.last_update ? (moment.isMoment(tpl.last_update) ? tpl.last_update : moment(tpl.last_update * 1000)) : false

                templates[tpl.id] = tpl
            })
        }

        Vue.set(state, 'templates', { ...templates })
    },
    setChecklistProp(state, payload) {
        var check = payload.check
        var prop = payload.prop
        var value = payload.value

        state.items[check][prop] = value
        Vue.set(state, 'items', { ...state.items })
    },
    setAnswerProp(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var prop = payload.prop
        var value = payload.value

        state.items[check].items[answer][prop] = value

        Vue.set(state, 'items', { ...state.items })
    },
    setComment(state, payload) {
        let check = payload.check
        let answer = payload.answer
        let aux = state.items[check].items[answer]

        aux.message = payload.message
        aux.image = payload.image ? payload.image : false

        Vue.set(state.items[check].items, answer, { ...aux })
        Vue.set(state, 'items', { ...state.items })
    },
    setAnswer(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date

        state.items[check].items[answer].value = value

        if (date) {
            var item = state.items[check].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) state.items[check].items[answer].employee = emp

        Vue.set(state, 'items', { ...state.items })
    },
    setApply(state, payload) {
        const check = payload.check
        const answer = payload.answer
        const emp = payload.emp
        const apply = payload.apply
        const date = payload.date

        state.items[check].items[answer].apply = apply

        if (date) {
            state.items[check].items[answer].complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        } else {
            state.items[check].items[answer].complete_date = false
        }

        if (emp) {
            state.items[check].items[answer].employee = emp
        }

        Vue.set(state, 'items', { ...state.items })
    },
    setReset(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].complete_date = false
        state.items[check].items[answer].employee = false
        state.items[check].items[answer].review = false
        state.items[check].items[answer].value = null

        Vue.set(state, 'items', { ...state.items })
    },
    setValidation(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date

        state.items[check].items[answer].review = {}
        if (date) {
            var item = state.items[check].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getEmployee'](emp)
            state.items[check].items[answer].review.employee = employee
        }
        state.items[check].items[answer].review.value = 1

        Vue.set(state, 'items', { ...state.items })
    },
    setUnvalidation(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].review = false

        Vue.set(state, 'items', { ...state.items })
    },

    resetStats(state, payload) {
        state.stats.alerts = {}
        state.stats.activity = {}
    },
    setStats(state, payload) {
        var stats = state.stats
        if (typeof stats[payload.where] === 'undefined') stats[payload.where] = {}
        stats[payload.where][payload.key_name] = payload.values
        state.stats = stats
    },

    // Common but may change in other tools
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    setScore(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },

    // Issues
    filterByState(state, payload) {
        let aItems = state.items

        Object.keys(aItems).map(function(item_id, i) {
            var item = aItems[item_id]

            if (payload.states.includes(item.status) || !payload.states.length) {
                item.is_visible = true
            } else {
                item.is_visible = false
            }
        })

        state.items = aItems
        Vue.set(state, 'items', { ...aItems })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
