<template>
    <div class="content library-detail hide-scrollbar right" :class="[$mq]">
        <div class="container scrollbar">
            <div class="header" v-if="['landscape', 'desktop'].includes($mq)" :data-val="file.type" :class="{ hide: hideHeader }">
                <div class="info">
                    <div class="left" :class="{ hasPreview: file.preview }" :style="file.preview != null ? { backgroundImage: 'url(' + file.preview + ')' } : {}"></div>
                    <div class="right">
                        <div class="title">
                            <div class="icon"></div>
                            <div class="name">{{ file.name }}</div>
                        </div>
                        <div class="description" v-if="file.description">{{ file.description }}</div>
                        <div class="meta">
                            <div class="update">{{ $t('library.last_update') }} {{ (file.upload_date * 1000) | moment('DD/MM/YYYY') }}</div>
                            <div class="highlight" v-if="file.highlight">{{ $t('library.resource_highlight') }}</div>
                        </div>
                        <!-- DOWNLOAD -->
                        <button v-if="file.download" class="btn download" @click.stop="download()">
                            <!-- {{ $t('menu_aux.action.download') }} -->
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="file.type == 1" class="image">
                <template v-if="files.length == 1">
                    <!-- <img class="picture" :src="files"> -->
                    <vue-picture-swipe class="picture" :items="files" :options="options"></vue-picture-swipe>
                </template>
                <template v-else>
                    <div class="gallery">
                        <!-- <template v-for="image in files"> -->
                        <vue-picture-swipe class="picture-swipe" :items="files" :options="options"></vue-picture-swipe>
                        <!-- </template> -->
                        <!-- <img v-for="image in files" class="picture" :src="image"> -->
                    </div>
                </template>
            </div>

            <div v-else-if="file.type == 2" class="video">
                <iframe v-if="file.file.includes('youtube')" class="player" rel="0" :src="files + '?rel=0&modestbranding=1&autohide=1&showinfo=0'" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen iv_load_policy="3"></iframe>
                <iframe v-else-if="file.file.includes('vimeo')" class="player" :src="files + '?title=0&byline=0&portrait=0&sidedock=0'" frameborder="0" allowfullscreen></iframe>
                <video class="player" :src="file.file" v-else controls>
                    <!-- <source :src="'http://techslides.com/demos/sample-videos/small.mp4'"> -->
                </video>
            </div>

            <template v-else-if="file.file.includes('.pdf') && file.type == 3">
                <div class="preview-pdf">
                    <div class="pdf-header">
                        <div class="pages">
                            <button class="btn next" @click="onNextPage()" alt="next"></button>
                            <button class="btn prev" @click="onPrevPage()" alt="previous"></button>
                            <!-- <span class="number">
							<span class="pdf-page-num">{{page}}</span> / <span class="pdf-page-count">{{ numPages ? numPages : '∞' }}</span>
						</span> -->
                        </div>
                        <div class="maximize">
                            <button class="btn maximize" @click="hideHeader = !hideHeader"></button>
                        </div>
                        <div class="zoom">
                            <button class="btn zoom-out" @click="onZoomOut()" alt="zoom out"></button>
                            <button class="btn zoom-in" @click="onZoomIn()" alt="zoom in"></button>
                            <!-- <span class="number">{{ formattedZoom }} %</span> -->
                        </div>
                    </div>
                    <!-- CODE HERE -->
                    <div class="pdf-pages scrollbar">
                        <canvas id="canvas-pdf"></canvas>
                    </div>
                </div>
            </template>
            <template v-else-if="file.type == 3 && !file.file.includes('.pdf')">
                <div class="file-container">
                    <div class="download-box">
                        <div class="icon"></div>
                        <div class="text-download">{{ file.file.split('/')[file.file.split('/').length - 1].slice(14) }}</div>
                        <div class="link-download">
                            <a :href="file.file" target="_blank">{{ $t('library.download') }}</a>
                        </div>
                    </div>
                </div>
            </template>
            <p v-else class="empty">{{ empty_text }}</p>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
// import pdf from 'pdfvuer'
import i18n from '@/i18n'
import VuePictureSwipe from 'vue-picture-swipe'
Vue.component('vue-picture-swipe', VuePictureSwipe)

export default {
    name: 'LibraryDetail',
    props: {
        file: { type: Object }
    },
    // components: { pdf },
    data() {
        return {
            empty_text: i18n.t('pdfjs.empty'),
            page: 1,
            numPages: 0,
            pdfdata: undefined,
            errors: [],

            // VARIABLES OF CONTROL
            hideHeader: false,
            options: vuePictureSwipeOptions,
            interval: null,

            // pdf
            pdfjsLib: null,
            pdfDoc: false,
            pageNum: 1,
            pageInit: 1,
            pageEnd: 1,
            pageRendering: false,
            pageNumPending: null,
            scale: 1,
            canvas: null,
            ctx: null,
            zoom: 1
        }
    },
    computed: {
        formattedZoom() {
            return this.scale == 'page-width' ? 100 : Number.parseInt(this.scale * 100)
        },

        categories() {
            // console.log(Object.values(this.$store.getters['library/getCategories']));
            return this.$store.getters['library/getCategories']
        },

        files() {
            if (this.file) {
                // IMAGE
                if (this.file.type == 1) {
                    var images = this.file.file.split('\|')
                    var result = []

                    for (var index in images) {
                        result.push({ src: images, thumbnail: images, w: 0, h: 0 })
                    }
                    return result
                }
                // VIDEO
                else if (this.file.type == 2) {
                    if (this.file.file.includes('youtube')) {
                        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                        const match = this.file.file.match(regExp)
                        var videoID = match && match[2].length === 11 ? match[2] : null

                        return 'https://www.youtube-nocookie.com/embed/' + videoID
                    } else if (this.file.file.includes('vimeo')) {
                        var regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/
                        var match = this.file.file.match(regExp)

                        return 'https://player.vimeo.com/video/' + match[3]
                    }
                    return ''
                }
                // DOCUMENT OR OTHERS
                else {
                    return this.file.file
                }
            }
        },

        user() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        pdfLibrary() {
            return this.$store.getters['library/getPdfLibrary']
        }
    },
    methods: {
        renderPage(num) {
            var self = this
            self.pageRendering = true
            // Using promise to fetch the page
            console.log('renderPage', num, self.pdfDoc)
            self.pdfDoc.getPage(num).then(function(page) {
                var viewport = page.getViewport({ scale: self.scale })

                self.canvas.height = viewport.height
                self.canvas.width = viewport.width

                // Render PDF page into canvas context
                var renderContext = {
                    canvasContext: self.ctx,
                    viewport: viewport
                }
                var renderTask = page.render(renderContext)

                // Wait for rendering to finish
                renderTask.promise
                    .then(function() {
                        self.pageRendering = false
                    })
                    .catch(e => {
                        console.log('Error rendering...', e)
                        self.pageRendering = false
                        self.pdfDoc.destroy()
                        self.pdfDoc = false
                        self.getPdf()
                    })
            })
        },

        queueRenderPage(num) {
            if (this.pageRendering) {
                this.pageNumPending = num
            } else {
                this.renderPage(num)
            }
        },

        onPrevPage() {
            if (this.pageNum <= 1) {
                return
            }
            this.pageNum--
            this.queueRenderPage(this.pageNum)
        },

        onNextPage() {
            if (this.pageNum >= this.pdfDoc.numPages) {
                return
            }
            this.pageNum++
            this.queueRenderPage(this.pageNum)
        },

        zoomCanvas(width) {
            this.canvas.style.width = width + '%'
        },

        onZoomIn() {
            this.zoom += 10
            this.zoomCanvas(this.zoom)
        },

        onZoomOut() {
            if (this.zoom == 100) return

            this.zoom -= 10
            this.zoomCanvas(this.zoom)
        },

        downloadIni(files) {
            var self = this
            log('downloadIni...', files)
            if (files.length > 0) {
                var file = files.pop()

                log('Downloading...', file)
                if (!self.$store.getters.getIsApp) {
                    var a = document.createElement('a')
                    a.setAttribute('href', file)
                    a.setAttribute('download', '')
                    a.setAttribute('target', '_blank')
                    a.click()

                    if (files.length == 0) {
                        clearInterval(self.interval)
                    }
                } else {
                    clearInterval(self.interval)
                    var fileName = file.replace(/^.*[\\\/]/, '')
                    var fileExtension = fileName.split('.').pop()

                    var fileTransfer = new FileTransfer()
                    fileTransfer.download(
                        file,
                        cordova.file.externalRootDirectory + 'Download/' + fileName,
                        function(entry) {
                            log('Successful download...')
                            log('download complete: ' + entry.toURL())
                            self.downloadIni(files)
                        },
                        function(error) {
                            log('download error source ' + error.source)
                            log('download error target ' + error.target)
                            log('upload error code' + error.code)
                            self.$snackbar.error({ duration: 5000, closeable: true, message: self.$t('library.download_error') })
                        },
                        null, // or, pass false
                        {}
                    )
                }
            } else {
                log(self.$t('library.downloaded'))
                self.$snackbar.success({ duration: 5000, closeable: true, message: self.$t('library.downloaded') })
            }
        },

        download() {
            var files = this.file.file.split('|')

            this.$snackbar.success({ duration: 2000, closeable: true, message: this.$t('library.downloading') })
            this.interval = setInterval(this.downloadIni, 1500, files)
        },

        doScale(up = true) {
            if (this.scale == 'page-width') this.scale = 1
            if (up) {
                this.hideHeader = true
                this.scale -= this.scale > 0.2 ? 0.1 : 0
            } else {
                this.hideHeader = true
                this.scale += this.scale < 2 ? 0.1 : 0
            }
        },
        loadPDFLib() {
            var self = this
            if (!this.pdfLibrary) {
                let Script = document.createElement('script')
                Script.setAttribute('src', (this.$store.getters.getIsApp ? '' : '/') + 'js/pdf.min.js?' + process.env.VUE_APP_VERSION)
                document.head.appendChild(Script)

                Script.onload = () => {
                    self.pdfjsLib = window['pdfjs-dist/build/pdf']
                    self.pdfjsLib.GlobalWorkerOptions.workerSrc = (this.$store.getters.getIsApp ? '' : '/') + 'js/pdf.worker.js?' + process.env.VUE_APP_VERSION
                    self.$store.commit('library/setPdfLibrary', true)
                    self.getPdf()
                }
            } else {
                self.pdfjsLib = window['pdfjs-dist/build/pdf']
                self.pdfjsLib.GlobalWorkerOptions.workerSrc = (this.$store.getters.getIsApp ? '' : '/') + 'js/pdf.worker.js?' + process.env.VUE_APP_VERSION
                self.getPdf()
            }
        },
        getPdf() {
            var self = this
            if (this.pdfLibrary && !self.pdfDoc) {
                self.pdfjsLib.getDocument(self.file.file).promise.then(function(pdfDoc_) {
                    if (self.pdfDoc) {
                        self.pdfDoc.destroy()
                    }
                    self.pdfDoc = pdfDoc_

                    self.zoom = 100

                    self.canvas = document.getElementById('canvas-pdf')
                    self.ctx = self.canvas.getContext('2d')
                    self.pageNumPending = null

                    if (!self.pageRendering) {
                        self.renderPage(self.pageInit)
                    }
                })
            }
        },
        findPos(obj) {
            return obj.offsetTop
        },

        getRefs() {
            return this.$refs
        }
    },
    mounted() {
        this.loadPDFLib()
        this.$store.dispatch('library/viewLog', { user: this.user, resource: this.file.id })

        if (['portrait'].includes(this.$mq)) {
            this.scale = 1
        }
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    watch: {
        show: function(s) {
            if (s) {
                this.loadPDFLib()
            }
        },
        page: function(p) {
            if (window.pageYOffset <= this.findPos(document.getElementById(p)) || (document.getElementById(p + 1) && window.pageYOffset >= this.findPos(document.getElementById(p + 1)))) {
                // window.scrollTo(0,this.findPos(document.getElementById(p)));
                document.getElementById(p).scrollIntoView()
            }
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
#content.library {
    overflow: hidden;

    > .content.library-detail {
        overflow: hidden;
        padding: 20px 0px;
        height: 100%;
        margin: 0;
        padding-bottom: 20px !important;

        .container {
            @include border-radius(6px);
            @include box-shadow($h: 0px, $v: 0px, $b: 6px, $s: 0px, $c: rgba($neutro-s90, 0.2));
            background-color: #f2f2f2;
            margin: 0 auto;
            height: 100%;
            width: calc(100% - 40px);
            overflow: auto;

            .header {
                background-color: #fff;
                width: 100%;
                max-height: 100%;
                padding: 10px;
                overflow: hidden;
                transition: 0.2s;

                &.hide {
                    max-height: 0px;
                    padding: 0;
                }

                // DEFAULT IMAGES IF TYPE = NULL
                .left {
                    @include background($size: 65px, $image: img('file_neutro.svg'));
                }
                .title > .icon {
                    @include background($size: 90%, $position: bottom, $image: img('file_neutro_s90.svg'));
                }

                // IMAGES BY TYPES
                &[data-val='1'] {
                    .left {
                        @include background($size: 70px, $image: img('image_neutro.svg'));
                    }

                    .title > .icon {
                        @include background($size: 110%, $image: img('image_neutro_s90.svg'));
                    }
                }

                &[data-val='2'] {
                    .left {
                        @include background($size: 50px, $image: img('video_neutro.svg'));
                    }

                    .title > .icon {
                        @include background($size: 70%, $image: img('video_neutro_s90.svg'));
                    }
                }

                &[data-val='3'] {
                    .left {
                        @include background($size: 65px, $image: img('file_neutro.svg'));
                    }

                    .title > .icon {
                        @include background($size: 90%, $image: img('file_neutro_s90.svg'));
                    }
                }

                .info {
                    .left {
                        float: left;
                        background-color: $neutro-t60;
                        width: 200px;
                        height: 120px;
                        @include border-radius(3px);

                        @media (max-width: 1200px) {
                            width: 150px;
                            height: 100px;
                        }

                        &.hasPreview {
                            @include background($size: cover, $color: $main-t90, $image: img('blank_document.svg'));
                        }
                    }

                    .right {
                        float: right;
                        width: calc(100% - 200px);
                        padding: 0 14px;

                        @media (max-width: 1200px) {
                            width: calc(100% - 150px);
                        }

                        .title {
                            padding-top: 5px;
                            position: relative;

                            .icon {
                                height: 15px;
                                width: 15px;
                                display: inline-block;
                                margin-right: 6px;
                                position: absolute;
                                top: 10px;
                            }

                            .name {
                                display: inline-block;
                                @include font-size(l);
                                font-family: $text-bold;
                                color: $neutro-s90;
                                text-indent: 20px;
                            }
                        }
                        .description {
                            margin-top: 10px;
                            @include font-size(m);
                            font-family: $text;
                            color: $neutro-s70;

                            @media (max-width: 1200px) {
                                @include font-size(xs);
                            }
                        }
                        .meta {
                            @include border-radius(3px);
                            margin-top: 10px;
                            background-color: $neutro-t70;
                            width: 100%;
                            overflow: hidden;

                            .category,
                            .update,
                            .highlight {
                                margin-top: 0px;
                                width: fit-content;
                                @include font-size(m);
                                font-family: $conden-light;
                                color: $neutro-s70;
                                padding: 6px 12px;

                                @media (max-width: 1200px) {
                                    @include font-size(s);
                                }
                            }

                            .category {
                                float: left;

                                .color {
                                    $icon-size: 12px;
                                    @include border-radius(50%);
                                    display: inline-block;
                                    width: $icon-size;
                                    height: $icon-size;
                                }

                                .separador {
                                    font-size: 13px;
                                }
                            }
                            .update {
                                // float: right;
                                float: left;
                            }

                            .highlight {
                                float: right;
                                font-family: $conden;
                                color: $main-dark;
                                padding-left: 15px;
                            }
                        }

                        .btn.download {
                            float: right;
                            margin-top: 10px;
                            height: 40px;
                            width: 40px;
                            @include background($image: img('download_fff.svg'), $size: 21px, $position: center center);
                        }
                    }
                }
                .highlight {
                    @include font-size(m);
                    @include background($size: 15px, $position: left center, $image: img('star_main.svg'));
                    padding-left: 20px;
                    font-family: $conden;
                    color: $main-dark;
                    width: 100%;
                    margin-top: 10px;
                    display: inline-block;
                }
            }

            // BOX ZIP FILES
            .file-container {
                @include display-flex();
                @include justify-content();
                width: 100%;
                height: 100%;
                padding-top: 40px;

                .download-box {
                    @include background($color: $neutro-t30);
                    @include display-flex();
                    @include justify-content(space-between);
                    @include align-items();
                    border-radius: 6px;
                    width: 60%;
                    height: 60px;
                    margin-bottom: 30px;

                    .icon {
                        @include background($size: 40px, $image: img('file_neutro.svg'));
                        width: 40px;
                        height: 40px;
                        margin-left: 10px;
                    }
                    .text-download {
                        @include font-size(sm);
                        @include text-ellipsis();
                        width: 90%;
                        font-family: $text-medium;
                        color: $neutro-s90;
                        padding-left: 10px;

                        border-radius: 4px;
                        margin-right: 10px;
                    }
                    .link-download {
                        @include font-size(sm);
                        color: $main;
                        font-family: $text-bold;
                        padding-right: 10px;
                        a {
                            border-bottom: 1px solid $main;
                        }
                    }
                }
            }
        }
    }

    // PDF STYLES
    .preview-pdf {
        // height: calc(100% - #{$menu-aux-height});
        height: 100%;
        overflow: hidden;
        position: relative;

        .pdf-header {
            @include border-radius(3px);
            position: absolute;
            top: 5px;
            left: 0;
            width: 100%;
            background-color: $neutro-t60;
            z-index: 1;
            padding: 10px 40px;
            overflow: hidden;
            height: fit-content;

            // .zoom, .pages {
            // 	height: 40px;
            // 	width: 50%;
            // 	display: inline-block;
            // 	float: left;
            // 	line-height: 40px;

            // 	.number {
            // 		display: inline-block;
            // 		height: inherit;
            // 		line-height: inherit;
            // 	}
            // }

            .pages,
            .maximize,
            .zoom {
                margin: 0 15px;
            }

            .pages {
                float: left;
            }

            .maximize,
            .zoom {
                float: right;
            }

            .btn {
                @include background($color: $neutro, $size: 18px);
                width: 40px;
                height: 40px;
                display: inline-block;
                margin: 0 7.5px;

                &.zoom-in {
                    background-image: img('more_primary.svg');
                }
                &.zoom-out {
                    background-image: img('less_primary.svg');
                }
                &.next {
                    background-image: img('right_main.svg');
                }
                &.prev {
                    background-image: img('left_main.svg');
                }
                &.maximize {
                    background-image: img('expand_primary.svg');
                }
            }

            .zoom {
                .btn,
                .number {
                    float: left;
                }
            }
            .pages {
                .btn,
                .number {
                    float: right;
                }
            }
        }

        .pdf-pages {
            @include display-flex();
            @include flex-direction(column);
            // @include flex-wrap(wrap); // Si hace el wrap se muestra 1 pagina cada vez
            position: absolute;
            top: 50px;
            left: 0;
            height: calc(100% - 40px);
            width: 100%;
            overflow: auto;
            background-color: $neutro-t60;
            // background-color: #333;

            > div {
                width: auto !important;
            }

            #canvas-pdf {
                width: 100%;
                height: auto;
                position: relative;
                top: 15px;
            }
        }
    }

    // .image {
    // 	height: fit-content;

    // 	> .picture {
    //           	@include border-radius(6px);
    // 		object-fit: contain;
    // 		max-width: 100%;
    // 		width: fit-content;
    // 		height: 250px;
    // 		margin: 25px auto;
    // 		overflow: hidden;
    // 		cursor: pointer;

    // 		@media (min-width: 1200px) {
    // 			height: calc(100vh - 400px);
    // 		}
    // 	}

    // > .gallery {
    // 	@include display-flex();
    // 	@include flex-direction();
    // 	width: fit-content;
    // 	max-width: 90%;
    // 	margin: 15px auto 0;
    // 	overflow: hidden;
    // 	height: fit-content;
    //     align-content: center;
    //     flex-flow: wrap;
    //     align-items: center;
    //     align-items: center;
    //     justify-content: center;

    // 	.picture {
    // 		flex-wrap: wrap;
    //          		@include border-radius(4px);
    //          		object-fit: cover;
    //          		overflow: hidden;
    // 		width: 160px;
    // 		min-width: 160px;
    // 		height: 160px;
    // 		margin: 15px 15px;
    // 		background-color: $neutro-t70;
    // 		cursor: pointer;
    // 	}
    // }
    // }

    .video {
        width: 100%;
        height: 75%;
        overflow: hidden;
        padding: 2%;
        text-align: center;

        .player {
            width: 100%;
            height: 100%;
            width: -webkit-fill-available;
            max-width: 900px;
            max-height: 600px;
            margin: 0 auto;
            border: 0;

            &:focus {
                border: 0;
                outline: none;
            }
        }
    }
}
</style>

<style lang="scss">
.content.library-detail {
    // FIX OF FIRST ITEM APPEARS AS DISPLAY NONE
    figure {
        display: block !important;
    }

    .image {
        .picture {
            figure > a > img {
                @include border-radius(6px);
                object-fit: contain;
                max-width: 100%;
                width: fit-content;
                height: 100%;
                margin: 25px auto;
                overflow: hidden;
                cursor: pointer;

                @media (min-width: 1200px) {
                    height: calc(100vh - 400px);
                }
            }
        }
    }

    .gallery {
        .picture-swipe {
            .itemscope {
                display: inline-block !important;
            }

            .my-gallery {
                @include display-flex();
                @include flex-direction();
                width: fit-content;
                max-width: 90%;
                margin: 15px auto 0;
                overflow: hidden;
                height: fit-content;
                align-content: center;
                flex-flow: wrap;
                align-items: center;
                align-items: center;
                justify-content: center;

                figure > a > img {
                    flex-wrap: wrap;
                    @include border-radius(4px);
                    object-fit: cover;
                    overflow: hidden;
                    width: 160px;
                    min-width: 160px;
                    height: 160px;
                    margin: 15px 15px;
                    background-color: $neutro-t70;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.library {
    .library-detail.portrait {
        margin: 0;
        width: 100%;

        .container {
            box-shadow: none;
            width: 100%;
            background-color: initial;
            padding: 0;

            .header {
                padding: 5px;

                .info {
                    .left {
                        width: 75px;
                        height: 75px;
                    }
                    .right {
                        width: calc(100% - 75px);
                        padding: 0;
                        padding-left: 10px;

                        .title {
                            .name {
                                @include font-size(sm);
                            }
                        }
                        .meta {
                            padding: 5px 10px;
                            margin-top: 5px;

                            .update {
                                @include font-size(xs);
                                padding: 0;
                            }
                            .highlight {
                                @include font-size(xs);
                                float: left;
                                padding: 0 20px;
                            }
                        }
                    }
                }
            }
        }

        .preview-pdf {
            .pdf-header {
                padding: 5px;
                top: 0;

                .pages {
                    margin: 0;
                    .btn {
                        margin: 0;
                        margin-right: 5px;
                    }
                }
                .maximize {
                    margin: 0;
                    margin-left: 5px;
                }
                .zoom {
                    margin: 0;
                    .btn {
                        margin: 0;
                        margin-left: 5px;
                    }
                }
            }
            .pdf-pages {
                top: 0;
                height: 100%;
            }
        }
    }
}
</style>
