<template>
    <div id="release-container">
        <div class="title-release">
            {{ data.title }}
        </div>
        <div class="content-release" v-html="data"></div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'

export default {
    props: {
        data: { type: undefined, default: '' }
    },
    data() {
        return {
            selectedEmails: [],
            linkList: false
        }
    },
    computed: {},
    methods: {
        translation(translation) {
            return i18n.t(translation)
        },
        linkAction(a) {
            var nameref = a.href
            console.log(nameref, a.className, store.getters.getIsApp)
            if (store.getters.getIsApp && !nameref.includes('http')) {
                var link = nameref.split('/')
                console.log('file:///' + window.location.pathname + '#/' + link[link.length - 1])
                window.location.href = 'file:///' + window.location.pathname + '#/' + link[link.length - 1]
            } else if (a.className == 'panel') {
                nameref = nameref.replace(window.location.origin, '')
                if (a.target == '_blank') {
                    window.open(nameref, 'blank')
                } else {
                    router.push(nameref)
                }
            } else {
                if (a.target == '_blank') {
                    window.open(a.href, '_blank')
                } else {
                    location.href = a.href
                }
            }
        }
    },
    created() {},
    mounted() {
        var self = this
        setTimeout(function() {
            self.linkList = document.getElementsByTagName('a')
            for (var i = 0; i < self.linkList.length; i++) {
                self.linkList[i].onclick = function(event) {
                    event.preventDefault()
                    self.linkAction(event.target)
                }
            }
        }, 300)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#release-container {
    @include display-flex();
    @include flex-direction(column);
    width: 100%;
    padding: 20px 40px;

    .title-release {
        @include font-size(l);
        font-family: $text-bold;
        color: $neutro-s90;
        padding-bottom: 6px;
        height: auto;
    }
    .content-release {
        h1,
        h2 {
            @include font-size(l);
        }
        div,
        p,
        li {
            @include font-size(m);
            font-family: $text-medium;
            color: $neutro-s70;
        }

        img {
            width: 100%;
            height: auto;
            max-width: 400px;
        }
        div {
            padding-top: 6px;
            padding-bottom: 6px;
        }
        p {
            padding: 6px 0px;
        }

        ul,
        ol {
            padding-top: 6px;
            padding-bottom: 6px;
            margin-left: 30px;

            li {
                list-style: disc;
            }
        }
        ul li::marker {
            font-size: 0.8em;
        }

        h1 {
            @include font-size(l);
            font-family: $text-bold;
            color: $neutro-s90;
        }
        h2 {
            @include font-size(ml);
            font-family: $text-bold;
            color: $neutro-s90;
        }

        a {
            font-size: inherit;
            color: $main-s30;
            text-decoration: underline;
            text-decoration-color: $main-s30;
        }
        .link-buttons,
        .app-buttons {
            @include align-items(center);
            @include background($image: img('right_arrow_main_s30.svg'), $position: right 12px center, $size: 15px);
            @include border-radius(4px);
            @include font-size(sm);
            background-color: $neutro-t50;
            padding-right: 35px;
            padding-left: 12px;
            box-shadow: 0px 0px 6px rgba(99, 108, 122, 0.3);
            height: 40px !important;
            width: fit-content;
            margin-left: 3px;
            display: inline-flex;
            margin-right: 6px;
            color: $main-s30;
            font-family: $conden;
            text-decoration: none;

            &:hover {
                background-color: hover($neutro-t50);
            }
        }
        .footer {
            @include font-size(s);
            color: $neutro-s50;
            font-family: $text-medium;
        }
    }
}
</style>
